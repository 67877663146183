import {MultilingualStringModel} from '../app/shared/model/multilingual-string.model';

export const REQUEST_ERROR: MultilingualStringModel = {
  en: 'An error occurred while processing your request.',
}

export const USER_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New user created.',
}

export const CONFIGURATIONS_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Configurations saved successfully!',
}

export const TERMINAL_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Terminal saved successfully!',
}

export const USER_DELETED: MultilingualStringModel = {
  en: 'User deleted.',
}

export const ROLE_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New role created.',
}

export const ORGANIZATION_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New organization created.',
}

export const ORGANIZATION_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Organization updated.',
}

export const USER_UPDATED: MultilingualStringModel = {
  en: 'User updated.',
}

export const ROLE_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Role updated.',
}

export const CATEGORY_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Category updated.',
}

export const TERMINAL_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Terminal updated.',
}

export const ROLE_DELETED: MultilingualStringModel = {
  en: 'Role deleted.',
}

export const ORGANIZATION_DELETED: MultilingualStringModel = {
  en: 'Organization deleted.',
}

export const SESSION_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Session deleted successfully.',
}

export const ROLES_UPDATED: MultilingualStringModel = {
  en: 'Roles updated.',
}

export const PASSWORD_CHANGED: MultilingualStringModel = {
  en: 'Password changed.',
}

export const SERVICE_STARTED: MultilingualStringModel = {
  en: 'Service started.',
}

export const SERVICE_STOPPED: MultilingualStringModel = {
  en: 'Service stopped.',
}

export const JOB_STATUS_RESET: MultilingualStringModel = {
  en: 'Job status reset.',
}

export const SERVICES_STARTED: MultilingualStringModel = {
  en: 'Services started.',
}

export const PROFILE_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Your profile has been updated successfully.',
}

export const PASSWORD_CHANGED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Your password has been changed successfully.',
}

export const SUCCESSFULLY_LOG_OUT: MultilingualStringModel = {
  en: 'You have logged out from the system.',
}

export const LOGIN_SUCCESSFUL: MultilingualStringModel = {
  en: 'Login successful!',
}

export const PERMISSIONS_CHANGED__SUCCESSFULLY: MultilingualStringModel = {
  en: 'Permissions changed successfully',
}

export const ALL_MESSAGES_DELETED: MultilingualStringModel = {
  en: 'All messages have been deleted.',
}

export const MAIL_DELETED: MultilingualStringModel = {
  en: 'Mail successfully deleted.',
}

export const CONNECTION_STARTED: MultilingualStringModel = {
  en: 'SignalR connection started.',
}

export const SUCCESSFULLY_STREAMING: MultilingualStringModel = {
  en: 'Successfully started streaming',
}

export const HUB_CONNECTION_STOPPED: MultilingualStringModel = {
  en: 'Connection stopped',
}

export const RESTARTED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Restarted successfully',
}

export const SUCCESS: MultilingualStringModel = {
  en: 'success',
}

export const URL_ADMIN_DELETED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'URL Redirection successfully deleted.',
}

export const URL_ADMIN_EDITED: MultilingualStringModel = {
  en: 'URL Redirection edited',
}

export const RULE_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New rule created.',
}

export const RULE_SAVED: MultilingualStringModel = {
  en: 'Rule saved successfully.',
}

export const RULE_SUCCESSFULLY_DELETED: MultilingualStringModel = {
  en: 'Rule successfully deleted.',
}

export const LOG_SUCCESSFULLY_DELETED: MultilingualStringModel = {
  en: 'Log successfully deleted.',
}

export const MACHINE_USER_CREATED: MultilingualStringModel = {
  en: 'New user created.',
}

export const PHONE_NUMBER_DELETED: MultilingualStringModel = {
  en: 'Phone number deleted.',
}

export const SOCIAL_MEDIA_DELETED: MultilingualStringModel = {
  en: 'Social media deleted.',
}

export const EMAIL_DELETED: MultilingualStringModel = {
  en: 'Email deleted.',
}

export const PHONE_NUMBER_SAVED: MultilingualStringModel = {
  en: 'Phone number saved.',
}

export const SOCIAL_MEDIA_SAVED: MultilingualStringModel = {
  en: 'Social media saved.',
}

export const EMAIL_SAVED: MultilingualStringModel = {
  en: 'Email saved.',
}

export const ADDRESS_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Address saved.',
}

export const ADDRESS_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Address updated.',
}

export const ADDRESS_DELETED: MultilingualStringModel = {
  en: 'Address deleted.',
}

export const EDUCATION_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New education created.',
}

export const EDUCATION_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Education updated.',
}

export const EDUCATION_DELETED: MultilingualStringModel = {
  en: 'Education deleted.',
}

export const WORK_EXPERIENCE_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New education created.',
}

export const WORK_EXPERIENCE_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Education updated.',
}

export const WORK_EXPERIENCE_DELETED: MultilingualStringModel = {
  en: 'Education deleted.',
}

export const REGISTERED_COMPANY_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New Company added.',
}

export const REGISTERED_COMPANY_DELETED: MultilingualStringModel = {
  en: 'Company deleted.',
}

export const DEPOSIT_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Deposit saved.',
}

export const DEPOSIT_DELETED: MultilingualStringModel = {
  en: 'Deposit deleted.',
}

export const SALARY_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Salary saved.',
}

export const SALARY_DELETED: MultilingualStringModel = {
  en: 'Salary deleted.',
}

export const LOAN_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Loan saved.',
}

export const LOAN_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Loan updated.',
}

export const LOAN_DELETED: MultilingualStringModel = {
  en: 'Loan deleted.',
}

export const NOTE_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Note saved.',
}

export const NOTE_DELETED: MultilingualStringModel = {
  en: 'Note deleted.',
}

export const PSYCHOLOGICAL_PORTRAIT_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Psychological portrait saved.',
}

export const PSYCHOLOGICAL_PORTRAIT_DELETED: MultilingualStringModel = {
  en: 'Psychological portrait deleted.',
}

export const RELATION_SAVED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Relation saved.',
}

export const RELATION_DELETED: MultilingualStringModel = {
  en: 'Relation deleted.',
}

export const PERSONAL_INFO_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Personal information updated.',
}

export const MACHINE_RESTARTED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Machine restarted.',
}


export const EXTENSION_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New extension created.',
}

export const EXTENSION_DELETED: MultilingualStringModel = {
  en: 'Extension deleted.',
}


export const EXTENSION_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'Extension updated.',
}


export const FILE_CATEGORY_CREATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'New file category created.',
}

export const FILE_CATEGORY_DELETED: MultilingualStringModel = {
  en: 'File category deleted.',
}


export const FILE_CATEGORY_UPDATED_SUCCESSFULLY: MultilingualStringModel = {
  en: 'File category updated.',
}

export const FILE_DELETED_FROM_SERVER: MultilingualStringModel = {
  en: 'File deleted from the server.',
}

export const FILE_DELETED: MultilingualStringModel = {
  en: 'File deleted.',
}

export const FOLDER_DELETED: MultilingualStringModel = {
  en: 'Folder deleted.',
}

export const FOLDER_LOCKED: MultilingualStringModel = {
  en: 'Folder locked.',
}

export const FILE_HOSTING_LOCKED: MultilingualStringModel = {
  en: 'File Hosting Locked.',
}

export const SCANNING_STARTED: MultilingualStringModel = {
  en: 'Scanning started.',
}

export const TRANSFER_STARTED: MultilingualStringModel = {
  en: 'Transfer started.',
}


