import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit,} from '@angular/core';
import {
  LEFT_BAR_WIDTH,
  LEFT_BAR_WIDTH_ICONS_ONLY,
  LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE,
  LEFT_BAR_WIDTH_MOBILE,
  LOCAL_STORAGE_LEFT_BAR_PINNED,
  LOCAL_STORAGE_ONLY_ICONS
} from '../../constants';
import {AuthService} from '../../services/auth.service';
import {PinnedStatusEnum} from '../../pinned-status.enum';
import {LeftBarOnlyIconsEnum} from '../../left-bar-only-icons.enum';
import {PermissionsEnum} from '../../permissions.enum';
import {NavigationService} from '../../services/navigation.service';
import {UtilService} from '../../services/util.service';
import {ThemeService} from '../../services/theme.service';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';
import {LeftBarItemsEnum} from '../../left-bar-items.enum';

@Component({
  selector: 'left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftBarComponent implements OnInit {
  public isWide = window.innerWidth > 769;
  public LEFT_BAR_WIDTH_ICONS_ONLY = this.isWide ? LEFT_BAR_WIDTH_ICONS_ONLY : LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE;
  public LEFT_BAR_WIDTH = this.isWide ? LEFT_BAR_WIDTH : LEFT_BAR_WIDTH_MOBILE;
  public readonly currentOrganizationId: string;
  // public leftBarVisible: boolean;
  public onlyIcons: boolean;
  public pinned: boolean;
  public hasAccessMachines = false;
  public hasAccessUsers = false;
  public hasAccessHostedServices = false;
  public hasAccessUrlAdministration = false;
  public hasAccessRoles = false;
  public hasAccessOrganizations = false;
  public hasAccessEmployees = false;
  public hasAccessBuilder = false;
  public hasAccessFileHosting = false;
  public isDarkMode = false;
  public items: (MenuItem & { imageUrl?: string })[] | undefined;
  public itemsForIdentity: (MenuItem & { imageUrl?: string })[] | undefined;
  public itemsForAdministration: (MenuItem & { imageUrl?: string })[] | undefined;
  public selectedItem: LeftBarItemsEnum;
  protected readonly LeftBarItemsEnum = LeftBarItemsEnum;

  public constructor(
    private elementRef: ElementRef,
    private navigationService: NavigationService,
    private utilService: UtilService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private router: Router,
  ) {
    this.currentOrganizationId = this.utilService.getCurrentOrganizationId();
  }

  @HostListener('window:resize', ['$event'])
  onOrientationChange(event: any) {
    const leftBarRes = document.querySelector<HTMLDivElement>('.p-sidebar.p-sidebar-left');
    if (leftBarRes) {
      this.isWide = window.innerWidth > 769;
      this.LEFT_BAR_WIDTH_ICONS_ONLY = this.isWide ? LEFT_BAR_WIDTH_ICONS_ONLY : LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE;
      this.LEFT_BAR_WIDTH = this.isWide ? LEFT_BAR_WIDTH : LEFT_BAR_WIDTH_MOBILE;
      leftBarRes.style.width = this.onlyIcons ? this.LEFT_BAR_WIDTH_ICONS_ONLY : this.LEFT_BAR_WIDTH;
    }
  }

  public ngOnInit(): void {
    const url = this.router.url;
    if (url === '/') {
      this.selectedItem = LeftBarItemsEnum.dashboard;
    } else if (url.includes('/terminals')) {
      this.selectedItem = LeftBarItemsEnum.terminals;
    } else if (url.includes('/personal-data')) {
      this.selectedItem = LeftBarItemsEnum.personalData;
    } else if (url.includes('/identity/users')) {
      this.selectedItem = LeftBarItemsEnum.users;
    } else if (url.includes('/identity/roles')) {
      this.selectedItem = LeftBarItemsEnum.roles;
    } else if (url.includes('/administration/fileCategories')) {
      this.selectedItem = LeftBarItemsEnum.fileCategories;
    } else if (url.includes('/administration/fileExtensions')) {
      this.selectedItem = LeftBarItemsEnum.fileExtensions;
    } else if (url.includes('/builder')) {
      this.selectedItem = LeftBarItemsEnum.builder;
    } else if (url.includes('/file-hosting')) {
      this.selectedItem = LeftBarItemsEnum.fileHosting;
    } else if (url.includes('/url-redirection')) {
      this.selectedItem = LeftBarItemsEnum.urlRedirections;
    } else if (url.includes('/url-shortener')) {
      this.selectedItem = LeftBarItemsEnum.urlShortener;
    }

    this.hasAccessMachines = this.authService.hasPermission(PermissionsEnum.readMachine, [
      PermissionsEnum.writeMachine, PermissionsEnum.readMachine, PermissionsEnum.writeMachine]);
    this.hasAccessUsers = this.authService.hasPermission(PermissionsEnum.readUser, [PermissionsEnum.deleteUser, PermissionsEnum.writeUser]);
    this.hasAccessHostedServices = this.authService.hasPermission(PermissionsEnum.manageHostedService);
    this.hasAccessUrlAdministration = this.authService.hasPermission(PermissionsEnum.organizationUrlRedirection_create,
      [PermissionsEnum.organizationUrlRedirection_update, PermissionsEnum.organizationUrlRedirection_delete]);
    this.hasAccessRoles = this.authService.hasPermission(PermissionsEnum.writeRole, [PermissionsEnum.deleteRole]);
    this.hasAccessOrganizations = this.authService.hasPermission(PermissionsEnum.writeOrganization, [PermissionsEnum.deleteOrganization]);
    this.hasAccessEmployees = this.authService.hasPermission(PermissionsEnum.readMachineUser, [
      PermissionsEnum.deleteMachineUser, PermissionsEnum.writeMachineUser, PermissionsEnum.readMachineUser,
      PermissionsEnum.writeMachineUser, PermissionsEnum.deleteMachineUser]);
    this.hasAccessBuilder = true; // todo
    this.hasAccessFileHosting = true; // todo

    this.initPinned();
    this.initOnlyIcons();
    // this.subscribeToVisibilityChanges();
    if (this.pinned) {
      this.navigationService.toggleLeftSidebar(true, this.onlyIcons);
    }
    this.navigationService.leftBarUnpinned.subscribe(() => {
      this.pinned = false;
    });
    this.navigationService.leftBarWidthChange.subscribe(() => {
      this.toggleOnlyIcons();
    });
    this.isDarkMode = this.themeService.getSelectedTheme() === 'dark';
    this.themeService.theme$.subscribe((theme) => {
      this.isDarkMode = theme === 'dark';

      this.initMenuItemsForIdentity();
      this.initMenuItemsForAdministration();
      this.cdr.detectChanges();
    });

    this.initMenuItemsForIdentity();
    this.initMenuItemsForAdministration();
    this.items?.forEach(item => this.isImageUrl(item.imageUrl))

    this.cdr.detectChanges();
  }

  isImageUrl(icon?: string): boolean {
    console.log(icon ? icon.endsWith('.png') || icon.endsWith('.jpg') || icon.endsWith('.jpeg') || icon.endsWith('.svg') : false)
    return icon ? icon.endsWith('.png') || icon.endsWith('.jpg') || icon.endsWith('.jpeg') || icon.endsWith('.svg') : false;
  }

  // public toggleLeftBarVisibility() {
  //   this.navigationService.toggleLeftSidebar();
  // }

  // private clickOutsideComponent = (event: MouseEvent) => {
  //   if (!((event.target as HTMLElement).closest('left-bar')) && this.leftBarVisible) {
  //     this.navigationService.toggleLeftSidebar();
  //   }
  // };

  // public toggleLeftBarPin() {
  //   if (this.pinned) {
  //     localStorage.setItem(LOCAL_STORAGE_LEFT_BAR_PINNED, PinnedStatusEnum.unpinned);
  //   } else {
  //     localStorage.setItem(LOCAL_STORAGE_LEFT_BAR_PINNED, PinnedStatusEnum.pinned);
  //   }
  //   this.pinned = !this.pinned;
  //   this.handleClicksOutsideComponent();
  // }

  public hasPermission(permission: string): boolean {
    return this.authService.hasPermission(permission)
  }

  public openHome() {
    this.selectedItem = LeftBarItemsEnum.dashboard;
    this.navigationService.navigateToHome().then();
    this.navigationService.closeRightBarPage();
  }

  public openTerminals() {
    this.selectedItem = LeftBarItemsEnum.terminals;
    this.navigationService.navigateToTerminals().then();
    this.navigationService.closeRightBarPage();
  }

  public openHostedServices() {
    this.navigationService.navigateToHostedServices().then();
    this.navigationService.closeRightBarPage();
  }

  public openUrlAdministrations() {
    this.selectedItem = LeftBarItemsEnum.urlRedirections;
    this.navigationService.navigateToUrlAdministrations().then();
    this.navigationService.closeRightBarPage();
  }

  public openUrlShortener() {
    this.selectedItem = LeftBarItemsEnum.urlShortener;
    this.navigationService.navigateToUrlShortener().then();
    this.navigationService.closeRightBarPage();
  }

  public openUsers() {
    this.selectedItem = LeftBarItemsEnum.users;
    this.navigationService.navigateToUsers().then();
    this.navigationService.closeRightBarPage();
  }

  public openRoles() {
    this.selectedItem = LeftBarItemsEnum.roles;
    this.navigationService.navigateToRoles().then();
    this.navigationService.closeRightBarPage();
  }

  public openEmployees() {
    this.selectedItem = LeftBarItemsEnum.personalData;
    this.navigationService.navigateToEmployees().then();
    this.navigationService.closeRightBarPage();
  }

  public openFileCategories() {
    this.selectedItem = LeftBarItemsEnum.fileCategories;
    this.navigationService.navigateToFileCategories().then();
    this.navigationService.closeRightBarPage();
  }

  public openFileExtensions() {
    this.selectedItem = LeftBarItemsEnum.fileExtensions;
    this.navigationService.navigateToFileExtensions().then();
    this.navigationService.closeRightBarPage();
  }

  public openBuilder() {
    this.selectedItem = LeftBarItemsEnum.builder;
    this.navigationService.navigateToBuilder().then();
    this.navigationService.closeRightBarPage();
  }

  public openFileHosting() {
    this.selectedItem = LeftBarItemsEnum.fileHosting;
    this.navigationService.navigateToFileHosting().then();
    this.navigationService.closeRightBarPage();
  }

  private initPinned() {
    const pinned = localStorage.getItem(LOCAL_STORAGE_LEFT_BAR_PINNED);
    if (pinned === PinnedStatusEnum.pinned) {
      this.pinned = true;
    } else if (pinned === PinnedStatusEnum.unpinned) {
      this.pinned = false;
    } else {
      localStorage.setItem(LOCAL_STORAGE_LEFT_BAR_PINNED, PinnedStatusEnum.pinned);
      this.pinned = true;
    }
  }

  // private subscribeToVisibilityChanges() {
  //   this.navigationService.toggleLeft.subscribe({
  //     next: open => {
  //       this.leftBarVisible = open.open;
  //       this.onlyIcons = open.onlyIcons;
  //       this.handleClicksOutsideComponent();
  //       this.cdr.detectChanges();
  //     },
  //     error: err => console.error(err),
  //   });
  // }

  // private handleClicksOutsideComponent() {
  //   if (!this.pinned && this.leftBarVisible) {
  //     setTimeout(() => {
  //       document.addEventListener('click', this.clickOutsideComponent);
  //     });
  //   } else {
  //     document.removeEventListener('click', this.clickOutsideComponent);
  //   }
  // }

  public toggleOnlyIcons() {
    if (this.onlyIcons) {
      localStorage.setItem(LOCAL_STORAGE_ONLY_ICONS, LeftBarOnlyIconsEnum.no);
    } else {
      localStorage.setItem(LOCAL_STORAGE_ONLY_ICONS, LeftBarOnlyIconsEnum.yes);
    }
    this.onlyIcons = !this.onlyIcons;
    this.navigationService.toggleLeftSidebar(true, this.onlyIcons);
    this.initMenuItemsForIdentity();
    this.initMenuItemsForAdministration()
    this.cdr.detectChanges();
  }

  private initOnlyIcons() {
    const onlyIcons = localStorage.getItem(LOCAL_STORAGE_ONLY_ICONS);
    if (onlyIcons === LeftBarOnlyIconsEnum.no) {
      this.onlyIcons = false;
      return;
    }
    onlyIcons || localStorage.setItem(LOCAL_STORAGE_ONLY_ICONS, LeftBarOnlyIconsEnum.no);
    this.onlyIcons = true;
  }
  public initMenuItemsForIdentity() {
    this.itemsForIdentity = [
      {
        expanded: this.itemsForIdentity && this.itemsForIdentity[0].expanded || (!this.itemsForIdentity && this.router.url.includes('/identity/')),
        label: this.onlyIcons ? '' :' Identity',
        icon: this.isDarkMode ? 'custom-icon custom-identity-dark' : 'custom-icon custom-identity',
        visible: this.hasAccessUsers,
        items: [
          {
            label: this.onlyIcons ? '' : 'Users',
            icon: 'pi pi-users',
            command: () => this.openUsers(),
            visible: this.hasAccessUsers,
            href: '/identity/users',
            enumKey: LeftBarItemsEnum.users,
          },
          {
            label: this.onlyIcons ? '' : 'Roles',
            icon: 'fa fa-street-view',
            command: () => this.openRoles(),
            visible: this.hasAccessRoles,
            href: '/identity/roles',
            enumKey: LeftBarItemsEnum.roles,
          },
        ],
      },


    ];
  }

  public initMenuItemsForAdministration () {
    this.itemsForAdministration = [
      {
        expanded: this.itemsForAdministration && this.itemsForAdministration[0].expanded || (!this.itemsForAdministration && this.router.url.includes('/administration/')),
        label: this.onlyIcons ? '' : 'Administration',
        icon: this.isDarkMode ? 'custom-icon custom-admin-dark' : 'custom-icon custom-admin',
        imageUrl:'/assets/icons/admin.png',
        visible: this.hasAccessHostedServices,
        items: [ {
          label: this.onlyIcons ? '' :'File Categories',
          icon: 'pi pi-file-edit',
          command: () => this.openFileCategories(),
          visible: this.hasAccessHostedServices,
          href: '/administration/fileCategories',
          enumKey: LeftBarItemsEnum.fileCategories,
        },
          {
            label: this.onlyIcons ? '' :'File Extensions',
            icon: 'pi pi-file-export',
            command: () => this.openFileExtensions(),
            visible: this.hasAccessHostedServices,
            href: '/administration/fileExtensions',
            enumKey: LeftBarItemsEnum.fileExtensions,
          },]
      },
    ]
  }
}
