import {Injectable, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {tap} from 'rxjs';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES,
  LOCAL_STORAGE_AUTH_USER_ID,
  LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES
} from '../constants';
import {UtilService} from './util.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {SUCCESSFULLY_LOG_OUT} from '../../../locale/multilingual-strings-constants';
import {NavigationService} from './navigation.service';
import {NotifierService} from './notifier.service';
import { HttpIdentityService } from './external/identity-client.service';
import { TokenModel } from './external/models/token.model';
import { OrganizationIdentityAggregatorService } from './external/organization-identity-aggregator.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public permissions: string[];
  private jwtHelper: JwtHelperService;
  private authUserId: string;
  public constructor(
    private injector: Injector,
    private notifierService: NotifierService,
    private navigationService: NavigationService,
  ) {
    this.jwtHelper = new JwtHelperService();
    this.permissions = this.permissions || this.getPermissionsFromToken();
    this.authUserId = this.getAuthUserId();
  }

  getHttpIdentityService() {
    return this.injector.get(HttpIdentityService);
  }
  
  getOrganizationIdentityAggregatorService() {
    return this.injector.get(OrganizationIdentityAggregatorService);
  }

  public login(data: FormGroup) {
    return this.getHttpIdentityService().login(data.value).pipe(
      tap((res: any) => {
        if (res) {
          this.authUserId = this.jwtHelper.decodeToken(res.value.accessToken.value).userSessionIdentifier;
          localStorage.setItem(LOCAL_STORAGE_AUTH_USER_ID, this.authUserId || '');

          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, res.value.accessToken.value || '');
          localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, res.value.refreshToken.value || '');
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES, res.value.accessToken.expires || '');
          localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES, res.value.refreshToken.expires || '');
          this.permissions = this.getPermissionsFromToken();
        }
      })
    )
  }

  public getAuthUserId() {
    return  localStorage.getItem(LOCAL_STORAGE_AUTH_USER_ID) || '';
  }

  public logout(): void {
    this.getHttpIdentityService().logout(this.getLogoutBody()).subscribe({
      next: () => {
        this.notifierService.handleSuccessRequest(SUCCESSFULLY_LOG_OUT, 'info', '');
        this.resetAccountAuthData();
        document.querySelectorAll('.p-component-overlay').forEach(elem => elem.remove());
        this.navigationService.navigateToLogin().then();
      },
      error: err => {
        this.notifierService.handleRequestError(err);
        this.resetAccountAuthData();
        this.navigationService.navigateToLogin().then();
      }
    })
  }

  public getLogoutBody() {
    return {
      accessToken: {
        value:  localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) ,
        expires: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES) ,
      },
      refreshToken: {
        value: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
        expires: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES)
      }
    } as TokenModel;
  }

  public resetAccountAuthData() {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public handleHttpResponse(res: any) {
    if (res.ok) {
      res.body = {
        succeed: true,
        value: JSON.parse(JSON.stringify(res.body)),
        errors: [],
      };
    }
    return res;
  }

  public refreshToken(){
    return this.getOrganizationIdentityAggregatorService().refreshToken();
  }

  public isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public isTokenExpired(): boolean {
    const token = this.getToken();
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  public getToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || null;
  }

  public hasRole(role: string) {
    const userRoles = this.getUserRolesFromToken();
    if (typeof userRoles === 'string') {
      return userRoles === role;
    }
    return userRoles && (userRoles.indexOf(role) !== -1);
  }

  public getPermissionsFromToken(): string[] {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['permission'] as string[] : [];
  }

  public hasPermission(permissionName: string, restPermissions: string[] = []): boolean {
    if (!this.permissions) {
      return true;
    }
    // return this.permissions.some(item => [permissionName, ...restPermissions].includes(item)); todo
    return true;
  }

  public getUserRolesFromToken(): string | string[] { // todo only string?
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : [];
  }

  public getUserNameFromToken(): string {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] as string : '';
  }

  public getUserIdFromToken(): string {
    const decodedToken = UtilService.getDecodedToken();
    return decodedToken ? decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] as string : '';
  }
}
