import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OrganizationModel} from './models/organization.model';
import {Response} from './models/response.model';
import {NewResponse} from './models/response';
import { Injectable } from '@angular/core';
import {FilterMetadata} from 'primeng/api';
import { TokenModel } from 'src/app/modules/file-hosting/model/token.model';
import { ResetPasswordModel } from 'src/app/modules/file-hosting/model/reset-password.model';
import { PasswordUpdateModel } from 'src/app/modules/file-hosting/model/password-update.model';
import { CreateUserModel } from 'src/app/modules/file-hosting/model/create-user.model';
import { API_URL } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class OrganizationIdentityAggregatorService {
  apiUrl = API_URL;

  private readonly urlAggregatorHealthz: string;
  private readonly urlAggregatorAccount: string;
  private readonly urlOrganizations: string;
  private readonly urlOrganizationUsers: string;
  private readonly urlOrganizationRoles: string;

  private readonly headers: HttpHeaders;
  private readonly options: object;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlAggregatorHealthz = `${this.apiUrl}/organization/healthz`;
    this.urlAggregatorAccount = `${this.apiUrl}/organization/Account`;
    this.urlOrganizations = `${this.apiUrl}/organization/organizations`;
    this.urlOrganizationUsers = `${this.apiUrl}/organization/organizationUsers`;
    this.urlOrganizationRoles = `${this.apiUrl}/organization/organizationRoles`;

  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlAggregatorHealthz}`,this.options);
  }


  // Account
  public confirmEmail(identifier: string, ConfirmationCode : string): Observable<Response<TokenModel>> {
    return this.http.get<Response<TokenModel>>(`${this.urlAggregatorAccount}/confirmEmail?identifier=${identifier}&ConfirmationCode=${ConfirmationCode}`,this.options);
  }

  public refreshToken(token?: TokenModel): Observable<Response<TokenModel>> {
    return this.http.post<Response<TokenModel>>(`${this.urlAggregatorAccount}/refreshToken`, token, this.options);
  }

  public resetPassword(body: ResetPasswordModel): Observable<Response<TokenModel>> { // todo
    return this.http.post<Response<TokenModel>>(`${this.urlAggregatorAccount}/resetPassword`, body, this.options);
  }

  // OrganizationRoles

  public getOrganizationRoles(organizationId: string, page: number, size: number,): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string []>>(`${this.urlOrganizationRoles}/${organizationId}?page=${page}&size=${size}`, this.options);
  }

  public addOrganizationRole(organizationId: string, roleId: string): Observable<NewResponse<null>> {
    return this.http.post<NewResponse<null>>(`${this.urlOrganizationRoles}/${organizationId}/${roleId}`, {}, this.options);
  }

  public deleteOrganizationRole(organizationId: string, roleId: string): Observable<NewResponse<null>> {
    return this.http.delete<NewResponse<null>>(`${this.urlOrganizationRoles}/${organizationId}/${roleId}`, this.options);
  }

  // Organizations
  public getOrganization(organizationId: string): Observable<Response<OrganizationModel>> {
    return this.http.get<Response<OrganizationModel>>(`${this.urlOrganizations}/${organizationId}`, this.options);
  }

  public addOrganization(parentId: string | null, body: OrganizationModel): Observable<Response<string>> {
    if (parentId) {
      return this.http.post<Response<string>>(`${this.urlOrganizations}?organizationId=${parentId}`, body, this.options);
    }
    return this.http.post<Response<string>>(`${this.urlOrganizations}`, body, this.options);
  }

  public updateOrganization(organizationId: string, body: OrganizationModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlOrganizations}/${organizationId}`, body, this.options);
  }

  public deleteOrganization(organizationId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlOrganizations}/${organizationId}`, this.options);
  }

  public getChildOrganizations(organizationId: string,page: number, size: number): Observable<NewResponse<OrganizationModel[]>> {
    if (organizationId) {
      return this.http.get<NewResponse<OrganizationModel[]>>(`${this.urlOrganizations}?page=${page}&size=${size}&organizationId=${organizationId}`, this.options);
    } else {
      return this.http.get<NewResponse<OrganizationModel[]>>(`${this.urlOrganizations}?page=${page}&size=${size}`, this.options);
    }
  }

  // Organization users
  public getAllUsersByOrganizationId(
    organizationId: string,
    page: number,
    size: number,
    filter?: {
      [s: string]: FilterMetadata | FilterMetadata[] | undefined;
    },
  ): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlOrganizationUsers}/${organizationId}?page=${page}&size=${size}`, this.options);
  }

  public createOrganizationUser(organizationId: string, body: CreateUserModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}`, body, this.options,);
  }

  public deleteUserFromRole(userId: string, organizationId: string, roleId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/${roleId}`, this.options);
  }

  public addUserToRole(organizationId: string, userId: string, roleId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/${roleId}`, {}, this.options);
  }

  public setUserPassword(userId: string, organizationId: string, body: PasswordUpdateModel): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${this.urlOrganizationUsers}/${organizationId}/${userId}/SetPassword`, body, this.options);
  }
}
