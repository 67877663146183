import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NewResponse} from './models/newResponse.model';
import {DirectoryModel} from './models/directory.model';
import {FileModel} from './models/file.model';
import {Response} from './models/response.model';
import {ExtensionCategoryModel} from './models/extension-category.model';
import {FileCategoryModel} from './models/file-category.model';
import {ExtensionModel} from './models/extension.model';
import {TerminalFileCategoryModel} from './models/terminal-file-category.model';
import {SyncFileModel} from './models/sync-file.model';
import {FileOptionsModel} from './models/file-options.model';
import {SyncStatusCountModel} from './models/sync-status-count.model';
import { API_URL } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {
  apiUrl = API_URL;

  private readonly urlDirectory: string;
  private readonly urlFile: string;
  private readonly urlHealthz: string;
  private readonly urlFileCategory: string;
  private readonly urlFileExtensionCategory: string;
  private readonly urlFileExtensions: string;
  private readonly urlFileSyncMetadata: string;
  private readonly urlTerminalFileCategory: string

  private readonly headers: HttpHeaders;
  private readonly options: object;

  constructor(private http: HttpClient) {

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlHealthz = `${this.apiUrl}/cis-cc-file-transfer/healthz`;
    this.urlDirectory = `${this.apiUrl}/cis-cc-file-transfer/Directory`;
    this.urlFile = `${this.apiUrl}/cis-cc-file-transfer/File`;
    this.urlFileCategory = `${this.apiUrl}/cis-cc-file-transfer/FileCategory`;
    this.urlFileExtensionCategory = `${this.apiUrl}/cis-cc-file-transfer/FileExtensionCategory`;
    this.urlFileExtensions = `${this.apiUrl}/cis-cc-file-transfer/FileExtensions`;
    this.urlFileSyncMetadata = `${this.apiUrl}/cis-cc-file-transfer/FileSyncMetadata`;
    this.urlTerminalFileCategory = `${this.apiUrl}/cis-cc-file-transfer/TerminalFileCategory`;
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlHealthz}`,this.options);
  }

  // Directory
  public getDirectory(terminalId: string, page: number, size: number, directoryId?: string): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlDirectory}/${terminalId}?page=${page}&size=${size}&directoryId=${directoryId}`, this.options);
  }

  public getDirectoryById(directoryId: string, terminalId: string): Observable<NewResponse<DirectoryModel>> {
    return this.http.get<NewResponse<DirectoryModel>>(`${this.urlDirectory}/${directoryId}/${terminalId}`, this.options);
  }

  // File
  public updateFile(body: FileModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFile}`, body, this.options);
  }

  public getFile(fileId: string, terminalId: string): Observable<Response<FileModel>> {
    return this.http.get<Response<FileModel>>(`${this.urlFile}/${terminalId}/${fileId}`, this.options);
  }

  public downloadFile(terminalId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.urlFile}/${terminalId}/download/${fileId}`, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: this.headers,
      params: new HttpParams(),
    });
  }

  public getFileDownloadLink(terminalId: string, fileId: string): string {
    return `${this.urlFile}/${terminalId}/download/${fileId}`;
  }

  public getFileIdByPath(filePath: string, terminalId: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlFile}/${terminalId}/path?filePath=${filePath}`, this.options);
  }

  public syncFile(body: SyncFileModel, terminalId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFile}/${terminalId}`, body, this.options);
  }

  public fileOptions(
    terminalId: string,
    page: number,
    size: number,
    syncStatus: number,
    startDate?: any,
    endDate?: any
  ): Observable<NewResponse<FileOptionsModel[]>> {
    const params = new URLSearchParams({
      syncStatus: syncStatus.toString(),
      page: page.toString(),
      size: size.toString(),
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    }).toString();

    return this.http.get<NewResponse<FileOptionsModel[]>>(`${this.urlFile}/${terminalId}/options?${params}`, this.options);
  }
  public downloadFiles(terminalId: string, fileIds: string[]): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.urlFile}/downloadSelected/${terminalId}`, fileIds, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: this.headers,
      params: new HttpParams(),
    });
  }

  public deleteFile(fileId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFile}/${fileId}`, this.options);
  }

  public getTerminalFiles (terminalId: string, page: number, size: number, directoryId: string): Observable<NewResponse<FileModel[]>> {
    return this.http.get<NewResponse<FileModel[]>>(`${this.urlFile}/terminal/${terminalId}?page=${page}&size=${size}&directoryId=${directoryId}`, this.options);
  }

  public getSyncStatusCounts(terminalId: string): Observable<Response<SyncStatusCountModel[]>> {
    return this.http.get<Response<SyncStatusCountModel[]>>(`${this.urlFile}/${terminalId}/GetSyncStatusCounts`, this.options);
  }

  // FileCategory
  public addFileCategory (body: FileCategoryModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFileCategory}`, body, this.options);
  }

  public getFileCategories(page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileCategory}?page=${page}&size=${size}`, this.options);
  }

  public getFileCategory(id: string) : Observable<Response<FileCategoryModel>> {
    return this.http.get<Response<FileCategoryModel>>(`${this.urlFileCategory}/${id}`, this.options);
  }

  public updateFileCategory(id: string, body: FileCategoryModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFileCategory}/${id}`, body, this.options);
  }

  public deleteFileCategory(id: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileCategory}/${id}`, this.options);
  }

  // FileExtensionCategory
  public addFileExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlFileExtensionCategory}`, body, this.options);
  }

  public updateExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>>{ // todo add id
    return this.http.put<Response<null>>(`${this.urlFileExtensionCategory}`, body, this.options);
  }

  public deleteExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileExtensionCategory}`,  {
      ...this.options,
      body,
    });
  }

  public getExtensionIds (fileCategoryId: string, page: number, size: number) : Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileExtensionCategory}/${fileCategoryId}?page=${page}&size=${size}`, this.options);
  }

  //FileExtensions
  public addExtension(body: ExtensionModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFileExtensions}`, body, this.options);
  }

  public getAllExtensions(page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileExtensions}?page=${page}&size=${size}`, this.options);
  }

  public getExtension(id: string) : Observable<Response<ExtensionModel>> {
    return this.http.get<Response<ExtensionModel>>(`${this.urlFileExtensions}/${id}`, this.options);
  }

  public updateExtension (id: string, body: ExtensionModel) : Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFileExtensions}/${id}`, body, this.options);
  }

  public deleteExtension(id: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileExtensions}/${id}`, this.options);
  }

  // //FileSyncMetadata
  //
  // public getSyncMetadata(fileSyncMetadataId: string) : Observable<NewResponse<{}>> {
  //   return this.http.get<NewResponse<{}>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}`, this.options);
  // }
  //
  // public updatePriority (fileSyncMetadataId: string, body: {}) : Observable<Response<null>> {
  //   return this.http.put<Response<null>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}/Priority`, body, this.options);
  // }
  //
  // public updateSyncStatus(fileSyncMetadataId: string, body: {}) : Observable<Response<null>> {
  //   return this.http.put<Response<null>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}/status`, body, this.options);
  // }
  //
  // public getStatuses (getAllFilesSyncStatus: string, terminalId: string, page: number, size: number): Observable<NewResponse<{}>> {
  //   return this.http.get<NewResponse<{}>>(`${this.urlFileSyncMetadata}/${getAllFilesSyncStatus}?terminalId=${terminalId}&page=${page}&size=${size}`, this.options);
  //
  // }

  //TerminalFileCategory
  public addTerminalFileCategory(body: TerminalFileCategoryModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlTerminalFileCategory}`, body, this.options);
  }

  public updateTerminalFileCategory (body: TerminalFileCategoryModel) : Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlTerminalFileCategory}`, body, this.options);
  }

  public deleteTerminalFileCategory (body: TerminalFileCategoryModel): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlTerminalFileCategory}`,  {
      ...this.options,
      body,
    });
  }

  public getTerminalFileCategories(terminalId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlTerminalFileCategory}/${terminalId}?page=${page}&size=${size}`, this.options);
  }
}
