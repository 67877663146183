import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LOCAL_STORAGE_LEFT_BAR_PINNED, LOCAL_STORAGE_ONLY_ICONS} from '../constants';
import {PinnedStatusEnum} from '../pinned-status.enum';
import {LeftBarOnlyIconsEnum} from '../left-bar-only-icons.enum';
import {ReplaySubject, Subject} from 'rxjs';
import {RightBarParamsModel} from '../model/right-bar-params.model';

@Injectable()
export class NavigationService {
  public openRightBar$ = new Subject<RightBarParamsModel>();
  public toggleLeft: Subject<{ open: boolean, onlyIcons: boolean }> = new ReplaySubject<{ open: boolean, onlyIcons: boolean }>(1);
  public leftBarVisible: boolean;
  public leftBarUnpinned: Subject<void> = new Subject<void>();
  public leftBarWidthChange: Subject<void> = new Subject<void>();
  private openedPages = 0;

  public constructor(
    private router: Router,
  ) {
  }

  public navigateToHome(): Promise<boolean> {
    return this.router.navigate(['/home']);
  }

  public navigateToTerminals(): Promise<boolean> {
    return this.router.navigate(['/terminals']);
  }

  public navigateToUsers(): Promise<boolean> {
    return this.router.navigate(['/identity/users']);
  }

  public navigateToRoles(): Promise<boolean> {
    return this.router.navigate(['/identity/roles']);
  }

  public navigateToEmployees(): Promise<boolean> {
    return this.router.navigate(['/personal-data']);
  }

  public navigateToHostedServices(): Promise<boolean> {
    return this.router.navigate(['/administration/hostedServices']);
  }

  public navigateToUrlAdministrations(): Promise<boolean> {
    return this.router.navigate(['/url-redirection']);
  }

  public navigateToUrlShortener(): Promise<boolean> {
    return this.router.navigate(['/url-shortener']);
  }

  public navigateToBlankComponent(): Promise<boolean> {
    return this.router.navigate(['/blank']);
  }

  public navigateToLogin(): Promise<boolean> {
    return this.router.navigate(['/login']);
  }

  public navigateToUserPermissions(): Promise<boolean> {
    return this.router.navigate(['/userPermissions']);
  }

  public navigateToFileCategories(): Promise<boolean> {
    return this.router.navigate(['/administration/fileCategories']);
  }

  public navigateToFileExtensions(): Promise<boolean> {
    return this.router.navigate(['/administration/fileExtensions']);
  }

  public navigateToBuilder(): Promise<boolean> {
    return this.router.navigate(['/builder']);
  }

  public navigateToFileHosting(): Promise<boolean> {
    return this.router.navigate(['/file-hosting']);
  }

  public toggleLeftSidebar(forceOpen = false, onlyIcons?: boolean) {
    if (forceOpen) {
      this.leftBarVisible = true;
    } else {
      this.leftBarVisible = !this.leftBarVisible;
      if (!this.leftBarVisible) {
        localStorage.setItem(LOCAL_STORAGE_LEFT_BAR_PINNED, PinnedStatusEnum.unpinned);
        this.leftBarUnpinned.next();
      }
    }
    this.toggleLeft.next({
      open: this.leftBarVisible,
      onlyIcons: !!onlyIcons || localStorage.getItem(LOCAL_STORAGE_ONLY_ICONS) === LeftBarOnlyIconsEnum.yes
    });
  }

  public toggleOnlyIcons() {
    this.leftBarWidthChange.next();
  }

  public openRightBarPage(value: string, data?: RightBarParamsModel, childValue?: string, relatedChildValue?: string) { // todo improve
    const rightBarParams: RightBarParamsModel = {value, opened: true};
    if (childValue) {
      rightBarParams.childValue = childValue;
    }
    if (relatedChildValue) {
      rightBarParams.relatedChildValue = relatedChildValue;
    }
    if (data) {
      if (data.personalDataId) {
        rightBarParams.personalDataId = data.personalDataId;
      }
      if (data.personalDataName) {
        rightBarParams.personalDataName = data.personalDataName;
      }
      if (data.machineId) {
        rightBarParams.machineId = data.machineId;
      }
      if (data.machineName) {
        rightBarParams.machineName = data.machineName;
      }
      if (data.userName) {
        rightBarParams.userName = data.userName;
      }
      if (data.userId) {
        rightBarParams.userId = data.userId;
      }
      if (data.urlAdminId) {
        rightBarParams.urlAdminId = data.urlAdminId;
      }
      if (data.urlAdmin) {
        rightBarParams.urlAdmin = data.urlAdmin;
      }
      if (data.cloneFor) {
        rightBarParams.cloneFor = data.cloneFor;
      }
      if (Object.prototype.hasOwnProperty.call(data, 'isOnline')) {
        rightBarParams.isOnline = data.isOnline;
      }
      if (Object.prototype.hasOwnProperty.call(data, 'isTerminalOnline')) {
        rightBarParams.isTerminalOnline = data.isTerminalOnline;
      }
    }
    this.openRightBar$.next(rightBarParams);
    this.openedPages++;
  }

  public closeRightBarPage(value?: string, childValue?: string) {
    this.openRightBar$.next({value, opened: false, childValue});
    this.openedPages--;
  }

  public rightBarOpened() {
    return this.openedPages > 0;
  }
}
