import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToastModule} from 'primeng/toast';
import {HttpDataService} from './shared/services/http/http-data.service';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastService} from './shared/services/toast.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UtilService} from './shared/services/util.service';
import {DialogService} from 'primeng/dynamicdialog';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {LoadingInterceptor} from './shared/interceptors/loading.interceptor';
import {JWT_OPTIONS, JwtHelperService} from '@auth0/angular-jwt';
import {AuthInterceptor} from './shared/interceptors/auth-interceptor.service';
import {MessageService} from 'primeng/api';
import {SharedModule} from './shared/shared.module';
import {NavigationService} from './shared/services/navigation.service';
import {NotifierService} from './shared/services/notifier.service';
import {ThemeService} from './shared/services/theme.service';
import {UploadProgressInterceptorService} from './shared/interceptors/upload-progress.interceptor.service';
import {LanguageService} from './shared/services/language.service';
import {KeyloggerService} from './shared/services/keylogger.service';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,
    HttpClientModule,
    ProgressSpinnerModule,
    SharedModule,
  ],
  providers: [
    DialogService,
    HttpDataService,
    ToastService,
    UtilService,
    KeyloggerService,
    NavigationService,
    NotifierService,
    JwtHelperService,
    ThemeService,
    MessageService,
    LanguageService,
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadProgressInterceptorService,
      multi: true
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
