import {environment} from '../../environments/environment';
import {IconSizeModel} from './model/icon-size.model';

export const API_URL = `${environment.url}api`;
export const FILE_HOSTING_URL = `${environment.fileHostingApiUrl}`;
export const URL_REDIRECTION_URL = `${environment.urlRedirectionApiUrl}`;
export const FILE_HOSTING_API_URL = `${FILE_HOSTING_URL}/api`;
export const URL_REDIRECTION_API_URL = `${URL_REDIRECTION_URL}api`;
export const URL_SHORTENER_URL = `${environment.urlShortenerUrl}`;
export const URL_SHORTENER_API_URL = `${URL_SHORTENER_URL}api`;
export const WS_URL = API_URL.replace('https', 'wss');
export const LOCAL_STORAGE_ACCESS_TOKEN = 'access-token';
export const LOCAL_STORAGE_AUTH_USER_ID = 'auth-user-id';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh-token';
export const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES = 'access-token-expires';
export const LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES = 'refresh-token-expires';
export const LOCAL_STORAGE_LEFT_BAR_PINNED = 'left-bar-pinned';
export const LOCAL_STORAGE_ONLY_ICONS = 'only-icons';
export const LOCAL_STORAGE_MACHINES_AUTO_UPDATE = 'machines-auto-update';
export const LOCAL_STORAGE_MACHINES_UPDATE_INTERVAL_MS = 'machines-auto-update-interval-ms';
export const LOCAL_STORAGE_TRANSFER_UPDATE_INTERVAL_MS = 'transfer-auto-update-interval-ms';
export const LOCAL_STORAGE_ACCOUNT_DETAILS = 'account-details';
export const LOCAL_STORAGE_ACCOUNT_PERMISSIONS = 'account-claims';

export const RIGHT_BAR_PROFILE = 'profile';
export const RIGHT_BAR_MAILBOX = 'mailbox';
export const RIGHT_BAR_SYNC_CONFIGURATIONS = 'sync-configurations';
export const RIGHT_BAR_SYNC_STATUS = 'sync-status';
export const RIGHT_BAR_ACCESS_PERMISSIONS = 'access-permissions';
export const RIGHT_BAR_MACHINES_PERMISSIONS = 'machines-permissions';
export const RIGHT_BAR_ORG_PERSONAL_DATA_PERMISSIONS = 'org-personal-data-permissions';
export const RIGHT_BAR_FILE_SERVICES = 'file-services';
export const RIGHT_BAR_USER_PROFILE = 'user-profile';
export const RIGHT_BAR_USER_SESSIONS = 'user-sessions';
export const RIGHT_BAR_REMOTE_DESKTOP = 'remote-desktop';
export const RIGHT_BAR_URL_ADMIN_RULES = 'url-admin-rules';
export const RIGHT_BAR_CLONE_URL_ADMIN_EMPLOYEE = 'clone-url-admin-employee';
export const RIGHT_BAR_CLONE_URL_ADMIN = 'clone-url-admin';
export const RIGHT_BAR_EMPLOYEES = 'employees';
export const RIGHT_BAR_EMPLOYEE = 'employee';
export const RIGHT_BAR_RELATED_EMPLOYEE = 'related-employee';
export const RIGHT_BAR_ADD_PERSONAL_DATA_RELATIONS = 'add-personal-data-relations';
export const RIGHT_BAR_LOGS = 'logs';

export const RIGHT_BAR_EMPLOYEE_REPORT = 'personal-data-report';
export const RIGHT_BAR_EMPLOYEE_PERSONAL_INFORMATION = 'personal-information';
export const RIGHT_BAR_EMPLOYEE_ADDRESSES = 'addresses';
export const RIGHT_BAR_EMPLOYEE_EDUCATION = 'education';
export const RIGHT_BAR_EMPLOYEE_WORK_EXPERIENCE = 'work-experience';
export const RIGHT_BAR_EMPLOYEE_URL_ADMIN = 'url-administration';
export const RIGHT_BAR_EMPLOYEE_REGISTERED_COMPANIES = 'registered-companies';
export const RIGHT_BAR_EMPLOYEE_COMMUNICATION = 'communication';
export const RIGHT_BAR_EMPLOYEE_NOTES = 'notes';
export const RIGHT_BAR_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS = 'psychological-portraits';
export const RIGHT_BAR_EMPLOYEE_RELATIONS = 'relations';
export const RIGHT_BAR_EMPLOYEE_PHOTOS = 'photos';
export const RIGHT_BAR_EMPLOYEE_ASSIGNED_MACHINES = 'assigned-machines';
export const RIGHT_BAR_EMPLOYEE_PHONE = 'phone';
export const RIGHT_BAR_EMPLOYEE_SOCIAL_MEDIA = 'social-media';
export const RIGHT_BAR_EMPLOYEE_EMAILS = 'emails';
export const RIGHT_BAR_EMPLOYEE_SALARY = 'salary';
export const RIGHT_BAR_EMPLOYEE_DEPOSITS = 'deposits';
export const RIGHT_BAR_EMPLOYEE_LOANS = 'loans';
export const RIGHT_BAR_RELATED_EMPLOYEE_REPORT = 'related-personal-data-report';
export const RIGHT_BAR_RELATED_EMPLOYEE_PERSONAL_INFORMATION = 'related-personal-information';
export const RIGHT_BAR_RELATED_EMPLOYEE_ADDRESSES = 'related-addresses';
export const RIGHT_BAR_RELATED_EMPLOYEE_EDUCATION = 'related-education';
export const RIGHT_BAR_RELATED_EMPLOYEE_WORK_EXPERIENCE = 'related-work-experience';
export const RIGHT_BAR_RELATED_EMPLOYEE_URL_ADMIN = 'related-url-administration';
export const RIGHT_BAR_RELATED_EMPLOYEE_REGISTERED_COMPANIES = 'related-registered-companies';
export const RIGHT_BAR_RELATED_EMPLOYEE_NOTES = 'related-notes';
export const RIGHT_BAR_RELATED_EMPLOYEE_PSYCHOLOGICAL_PORTRAITS = 'related-psychological-portraits';
export const RIGHT_BAR_RELATED_EMPLOYEE_RELATIONS = 'related-relations';
export const RIGHT_BAR_RELATED_EMPLOYEE_PHOTOS = 'related-photos';
export const RIGHT_BAR_RELATED_EMPLOYEE_ASSIGNED_MACHINES = 'related-assigned-machines';
export const RIGHT_BAR_RELATED_EMPLOYEE_PHONE = 'related-phone';
export const RIGHT_BAR_RELATED_EMPLOYEE_SOCIAL_MEDIA = 'related-social-media';
export const RIGHT_BAR_RELATED_EMPLOYEE_EMAILS = 'related-emails';
export const RIGHT_BAR_RELATED_EMPLOYEE_SALARY = 'related-salary';
export const RIGHT_BAR_RELATED_EMPLOYEE_DEPOSITS = 'related-deposits';
export const RIGHT_BAR_RELATED_EMPLOYEE_LOANS = 'related-loans';

export const LEFT_BAR_WIDTH = '250px';

export const LEFT_BAR_WIDTH_MOBILE = '140px';

export const LEFT_BAR_WIDTH_ICONS_ONLY = '80px';

export const LEFT_BAR_WIDTH_ICONS_ONLY_MOBILE = '50px';

export const DEFAULT_LOCALE = 'en';

export const SKIP_LOADING = 'skipLoading';

export const DEFAULT_TABLE_ROWS_OPTIONS = [50, 100, 200];
export const MACHINE_ACTIVITY_LOGS_LIMIT = 200;
export const MACHINE_FILE_SERVICES_LIMIT = 200;
export const USERS_LIMIT = 200;
export const USER_SESSIONS_LIMIT = 200;
export const HOSTED_SERVICES_LIMIT = 200;
export const HOSTED_SERVICES_JOBS_LIMIT = 200;
export const EMPLOYEES_LIMIT = 200;
export const HOSTING_FILES_LIMIT = 200;
export const URL_ADMIN_LIMIT = 200;
export const URL_SHORTENER_LIMIT = 200;
export const URL_REDIRECTION_LIMIT = 200;
export const URL_ADMIN_LOGS_LIMIT = 200;
export const FILE_HOSTING_LOGS_LIMIT = 200;
export const URL_SHORTENER_LOGS_LIMIT = 200;
export const URL_ADMIN_RULES_LIMIT = 200;
export const URL_STATISTICS_LIMIT = 200;
export const ROLES_LIMIT = 200;
export const ADDRESSES_LIMIT = 200;
export const EDUCATION_LIMIT = 200;
export const WORKS_LIMIT = 200;
export const REGISTERED_COMPANIES_LIMIT = 200;
export const PHONE_NUMBERS_LIMIT = 200;
export const SOCIAL_MEDIA_LIMIT = 200;
export const EMAILS_LIMIT = 200;
export const MAX_PAGING_LIMIT = 999999; // todo
export const MACHINES_LIMIT = 200;
export const FILE_CATEGORIES_LIMIT = 200;
export const FILE_EXTENSIONS_LIMIT = 200;
export const DEFAULT_TABLE_LIMIT = 200;
export const DEPOSITS_LIMIT = 200;
export const SALARIES_LIMIT = 200;
export const LOANS_LIMIT = 200;
export const NOTES_LIMIT = 200;
export const ASSIGNED_MACHINES_LIMIT = 200;
export const PSYCHOLOGICAL_PORTRAITS_LIMIT = 200;
export const RELATIONS_LIMIT = 200;
export const LOCAL_STORAGE_MACHINES_LIMIT = 'machines-limit';
export const LOCAL_STORAGE_FILE_CATEGORIES_LIMIT = 'file-categories-limit';
export const LOCAL_STORAGE_FILE_EXTENSIONS_LIMIT = 'file-extensions-limit';
export const LOCAL_STORAGE_MACHINE_ACTIVITY_LOGS_LIMIT = 'machine-activity-logs-limit';
export const LOCAL_STORAGE_MACHINE_FILE_SERVICES_LIMIT = 'machine-file-services-limit';
export const LOCAL_STORAGE_USERS_LIMIT = 'users-limit';
export const LOCAL_STORAGE_USER_SESSIONS_LIMIT = 'user-sessions-limit';
export const LOCAL_STORAGE_HOSTED_SERVICES_LIMIT = 'hosted-services-limit';
export const LOCAL_STORAGE_HOSTED_SERVICES_JOBS_LIMIT = 'hosted-services-jobs-limit';
export const LOCAL_STORAGE_EMPLOYEES_LIMIT = 'employees-limit';
export const LOCAL_STORAGE_URL_ADMIN_LIMIT = 'url-admin-limit';
export const LOCAL_STORAGE_URL_SHORTENER_LIMIT = 'url-shortener-limit';
export const LOCAL_STORAGE_URL_REDIRECTION_LIMIT = 'url-redirection-limit';
export const LOCAL_STORAGE_URL_ADMIN_LOGS_LIMIT = 'url-admin-logs-limit';
export const LOCAL_STORAGE_URL_SHORTENER_LOGS_LIMIT = 'url-shortener-logs-limit';
export const LOCAL_STORAGE_FILE_HOSTING_LOGS_LIMIT = 'file-hosting-logs-limit';
export const LOCAL_STORAGE_URL_ADMIN_RULES_LIMIT = 'url-admin-rules-limit';
export const LOCAL_STORAGE_URL_STATISTICS_LIMIT = 'url-admin-statistics-limit';
export const LOCAL_STORAGE_ROLES_LIMIT = 'roles-limit';
export const LOCAL_STORAGE_ADDRESSES_LIMIT = 'addresses-limit';
export const LOCAL_STORAGE_EDUCATION_LIMIT = 'education-limit';
export const LOCAL_STORAGE_WORKS_LIMIT = 'works-limit';
export const LOCAL_STORAGE_REGISTERED_COMPANIES_LIMIT = 'registered-companies-limit';
export const LOCAL_STORAGE_PHONE_NUMBERS_LIMIT = 'phone-numbers-limit';
export const LOCAL_STORAGE_SOCIAL_MEDIA_LIMIT = 'social-media-limit';
export const LOCAL_STORAGE_EMAILS_LIMIT = 'emails-limit';
export const LOCAL_STORAGE_DEPOSITS_LIMIT = 'deposits-limit';
export const LOCAL_STORAGE_SALARIES_LIMIT = 'salaries-limit';
export const LOCAL_STORAGE_LOANS_LIMIT = 'loans-limit';
export const LOCAL_STORAGE_NOTES_LIMIT = 'notes-limit';
export const LOCAL_STORAGE_ASSIGNED_MACHINES_LIMIT = 'assigned-machines-limit';
export const LOCAL_STORAGE_PSYCHOLOGICAL_PORTRAITS_LIMIT = 'psychological-portraits-limit';
export const LOCAL_STORAGE_RELATIONS_LIMIT = 'relations-limit';
export const LOCAL_STORAGE_THEME = 'theme';
export const LOCAL_STORAGE_LANGUAGE = 'language';

export const SOCIAL_MEDIA_NAMES = [
  'Facebook',
  'Instagram',
  'Twitter',
  'LinkedIn',
  'Pinterest',
  'Snapchat',
  'YouTube',
  'Reddit',
  'Tumblr',
  'WhatsApp',
  'Telegram',
  'WeChat',
  'Viber',
  'Skype',
  'Pinterest',
  'Flickr',
  'Medium',
  'Quora',
  'Vimeo',
  'Periscope',
  'TikTok',
  'Snapchat',
  'SoundCloud',
  'Foursquare',
  'Meetup',
  'Yelp',
  'Digg',
  'Mix',
  'DeviantArt',
  'Google+',
  'Behance',
  'Dribbble',
  '500px',
  'SlideShare',
  'StumbleUpon',
  'VKontakte',
  'Odnoklassniki',
  'LiveJournal',
  'Xing',
  'Renren',
  'Sina Weibo',
  'We Heart It',
  'Twitch',
  'Myspace',
  'Tagged',
  'Hi5',
  'Orkut',
  'Renren',
  'Meowchat',
  'Path',
  'Periscope',
  'Yo',
  'Plurk',
  'MeetMe',
  'Amino Apps',
  'Nextdoor',
  'Crunchyroll',
  'Taringa!',
  'Badoo',
  'Twoo',
  'Qzone',
  'Renren',
  'Weibo',
  'Douban',
  'Mastodon',
  'Ello',
  'Gab',
  'MeWe',
  'Parler',
  'Rumble',
  'Voat',
  'Snapzu',
  'Minds',
  'Flote',
  'Diaspora',
  'Friendica',
  'Gettr',
  'Triller',
  'Trovo',
  'Clubhouse',
  'Anchor',
  'Rumble',
  'Kuaishou',
  'Triller',
  'Zhihu',
  'Meipai',
  'Rediff',
  'Plaxo',
  'Zorpia',
  'Ning',
  'Xanga',
  'TagWorld',
  'Shutterfly',
  'Webshots',
  'Flixster',
  'Hyves',
  'Netlog',
  'Renren',
  'Hi5',
  'Mixi',
  'Cyworld',
  'Orkut',
  'Friendster',
  'Jaiku',
  'Plazes',
  'Dopplr',
  'Last.fm',
  'LiveJournal',
  'Xanga',
  'TypePad',
  'Blogger',
  'WordPress',
  'Medium',
  'Ghost',
  'Squarespace',
  'Wix',
  'Weebly',
  'Jimdo',
  'Strikingly',
  'Zoho Sites',
  'Google Sites',
  'Homestead',
  'Tripod',
  'Bravenet',
  'Angelfire',
  'Freewebs',
  'Webs',
  'Geocities',
  'Neocities',
  'Carrd',
  'About.me',
  'Flavors.me',
  'Cargo',
  'Carbonmade',
  'Behance',
  'Dribbble',
  'Coroflot',
  'Cargo',
  'Portfoliobox',
  'ArtStation',
  'Designspiration',
  'Dunked',
  'Krop',
  'Carbonmade',
  'Semplice',
  'Adobe Portfolio',
  'Journo Portfolio',
  'Flickr',
  '500px',
  'Behance',
  'Dribbble',
  'DeviantArt',
  'ArtStation',
  'Pixiv',
  'Designspiration',
  'Ello',
  'EyeEm',
  'Adobe Stock',
  'Getty Images',
  'Shutterstock',
  'Unsplash',
  'Pexels',
  'Pixabay',
  'Flickr',
  '500px',
  'Photobucket',
  'SmugMug',
  'Snapfish',
  'Shutterfly',
  'Zenfolio',
  'Mix',
  'StumbleUpon',
  'Diigo',
  'Pocket',
  'Instapaper',
  'Flipboard',
  'Scoop.it',
  'Delicious',
  'Digg',
  'Slashdot',
  'Fark',
  'Newsvine',
  'MetaFilter',
  'Y Combinator',
  'Product Hunt',
  'Hacker News',
  'Designer News',
  'Reddit',
  'Voat',
  'Snapzu',
  'Minds',
  'Flote',
  'Gab',
  'Parler',
  'Gettr',
  'Triller',
  'Clash',
  'DLive',
  'LiveLeak',
  'Rumble',
  'Bitchute',
  'BitTube',
  'Brighteon',
  'BrandNewTube',
  'Odysee',
  'PeerTube',
  'VidLii',
  'LBRY',
  'D.Tube',
  'BitChute',
  'Rokfin',
  'Xfinite',
  'Stitcher',
  'Spreaker',
  'BlogTalkRadio',
  'Anchor',
  'Podbean',
  'Buzzsprout',
  'Libsyn',
  'SoundCloud',
  'Mixlr',
  'Acast',
  'Blubrry',
  'Transistor',
  'Simplecast',
  'Zencast',
  'RedCircle',
  'Castos',
  'Pippa',
  'Podomatic',
  'Megaphone',
  'Whooshkaa',
  'Audioboom',
  'Chartable',
  'Podchaser',
  'Listen Notes',
  'Podknife',
  'Vurbl',
  'Podtail',
  'RadioPublic',
  'Player FM',
  'iHeartRadio',
  'TuneIn',
  'Pandora',
  'Spotify',
  'Apple Podcasts',
  'Google Podcasts',
  'Stitcher',
  'Deezer',
  'Podbean',
  'Overcast',
  'Castbox',
  'Pocket Casts',
  'RSS',
  'Castro',
  'Breaker',
  'Podchaser',
  'Listen Notes',
  'Podknife',
  'Vurbl',
  'Podtail',
  'RadioPublic',
  'Player FM',
  'iHeartRadio',
  'TuneIn',
  'Pandora',
  'Spotify',
  'Apple Music',
  'Google Play Music',
  'Amazon Music',
  'Tidal',
  'Deezer',
  'SoundCloud',
  'Mixcloud',
  'Bandcamp',
  'ReverbNation',
  'SoundClick',
  'DatPiff',
  'BeatStars',
  'Clyp',
  'HearThisAt',
  'Audiomack',
  'Soundation',
];

export const ICON_SIZES: IconSizeModel[] = [
  {name: 'Details', icon: 'pi pi-list', code: 'details', class: 'iconsDetails',},
  {name: 'Extra Large Icons', icon: 'pi pi-desktop', code: 'extraLarge', class: 'extraLargeIcons', iconSize: '1.2rem'},
  {name: 'Large Icons', icon: 'pi pi-desktop', code: 'large', class: 'largeIcons', iconSize: '1rem'},
  {name: 'Medium Icons', icon: 'pi pi-desktop', code: 'medium', class: 'mediumIcons', iconSize: '0.8rem'},
  {name: 'Small Icons', icon: 'pi pi-desktop', code: 'small', class: 'smallIcons', iconSize: '0.6rem'},
];

export const IMAGE_EXTENSIONS = ['JPEG', 'JPG', 'PNG ', 'WEBP', 'ICO', 'SVG', 'RAW', 'HEIC', 'HEIF', 'GIF', 'BMP'];
export const TEXT_EXTENSIONS = ['ASC', 'DOC', 'DOCX ', 'RTF', 'MSG', 'PDF', 'TXT', 'WPD', 'WPS'];

export const FILE_VALIDATION_MESSAGE =
  `Only files with the following extensions: ${IMAGE_EXTENSIONS},
  ${TEXT_EXTENSIONS}
  and under 2MB can be previewed.
  Please ensure your file meets these requirements.`

export const MAX_FILE_SIZE_FOR_PREVIEW = 2097152;


export const  countryCoordinates = {
  'AF': { lat: 34.5553, lng: 69.2075 }, // Afghanistan
  'AL': { lat: 41.3275, lng: 19.8189 }, // Albania
  'DZ': { lat: 36.7372, lng: 3.0860 }, // Algeria
  'AD': { lat: 42.5063, lng: 1.5218 }, // Andorra
  'AO': { lat: -8.8390, lng: 13.2894 }, // Angola
  'AG': { lat: 17.1274, lng: -61.8468 }, // Antigua and Barbuda
  'AR': { lat: -34.6037, lng: -58.3816 }, // Argentina
  'AM': { lat: 40.1792, lng: 44.4991 }, // Armenia
  'AU': { lat: -35.2809, lng: 149.1300 }, // Australia
  'AT': { lat: 48.2082, lng: 16.3738 }, // Austria
  'AZ': { lat: 40.4093, lng: 49.8671 }, // Azerbaijan
  'BS': { lat: 25.0343, lng: -77.3963 }, // Bahamas
  'BH': { lat: 26.2285, lng: 50.5860 }, // Bahrain
  'BD': { lat: 23.8103, lng: 90.4125 }, // Bangladesh
  'BB': { lat: 13.1939, lng: -59.5432 }, // Barbados
  'BY': { lat: 53.9045, lng: 27.5615 }, // Belarus
  'BE': { lat: 50.8503, lng: 4.3517 }, // Belgium
  'BZ': { lat: 17.5046, lng: -88.1962 }, // Belize
  'BJ': { lat: 6.3703, lng: 2.3912 }, // Benin
  'BT': { lat: 27.5142, lng: 89.7020 }, // Bhutan
  'BO': { lat: -16.5000, lng: -68.1500 }, // Bolivia
  'BA': { lat: 43.8563, lng: 18.4131 }, // Bosnia and Herzegovina
  'BW': { lat: -24.6282, lng: 25.9231 }, // Botswana
  'BR': { lat: -15.7939, lng: -47.8828 }, // Brazil
  'BN': { lat: 4.9031, lng: 114.9398 }, // Brunei
  'BG': { lat: 42.6977, lng: 23.3219 }, // Bulgaria
  'BF': { lat: 12.3714, lng: -1.5197 }, // Burkina Faso
  'BI': { lat: -3.3731, lng: 29.9189 }, // Burundi
  'CV': { lat: 14.9167, lng: -23.5167 }, // Cape Verde
  'KH': { lat: 11.5564, lng: 104.9282 }, // Cambodia
  'CM': { lat: 3.8480, lng: 11.5021 }, // Cameroon
  'CA': { lat: 45.4215, lng: -75.6972 }, // Canada
  'CF': { lat: 4.3947, lng: 18.5582 }, // Central African Republic
  'TD': { lat: 12.1048, lng: 15.0445 }, // Chad
  'CL': { lat: -33.4489, lng: -70.6693 }, // Chile
  'CN': { lat: 39.9042, lng: 116.4074 }, // China
  'CO': { lat: 4.7110, lng: -74.0721 }, // Colombia
  'KM': { lat: -11.7172, lng: 43.2473 }, // Comoros
  'CG': { lat: -4.2634, lng: 15.2429 }, // Congo
  'CR': { lat: 9.9281, lng: -84.0907 }, // Costa Rica
  'HR': { lat: 45.8150, lng: 15.9819 }, // Croatia
  'CU': { lat: 23.1136, lng: -82.3666 }, // Cuba
  'CY': { lat: 35.1856, lng: 33.3823 }, // Cyprus
  'CZ': { lat: 50.0755, lng: 14.4378 }, // Czech Republic
  'DK': { lat: 55.6761, lng: 12.5683 }, // Denmark
  'DJ': { lat: 11.5721, lng: 43.1456 }, // Djibouti
  'DM': { lat: 15.3000, lng: -61.3833 }, // Dominica
  'DO': { lat: 18.4861, lng: -69.9312 }, // Dominican Republic
  'EC': { lat: -0.1807, lng: -78.4678 }, // Ecuador
  'EG': { lat: 30.0444, lng: 31.2357 }, // Egypt
  'SV': { lat: 13.6929, lng: -89.2182 }, // El Salvador
  'GQ': { lat: 3.7523, lng: 8.7737 }, // Equatorial Guinea
  'ER': { lat: 15.3229, lng: 38.9251 }, // Eritrea
  'EE': { lat: 59.4370, lng: 24.7535 }, // Estonia
  'SZ': { lat: -26.5225, lng: 31.4659 }, // Eswatini
  'ET': { lat: 9.1450, lng: 40.4897 }, // Ethiopia
  'FJ': { lat: -18.1416, lng: 178.4419 }, // Fiji
  'FI': { lat: 60.1699, lng: 24.9384 }, // Finland
  'FR': { lat: 48.8566, lng: 2.3522 }, // France
  'GA': { lat: 0.4162, lng: 9.4673 }, // Gabon
  'GM': { lat: 13.4549, lng: -16.5790 }, // Gambia
  'GE': { lat: 41.7151, lng: 44.8271 }, // Georgia
  'DE': { lat: 52.5200, lng: 13.4050 }, // Germany
  'GH': { lat: 5.6037, lng: -0.1870 }, // Ghana
  'GR': { lat: 37.9838, lng: 23.7275 }, // Greece
  'GD': { lat: 12.0561, lng: -61.7488 }, // Grenada
  'GT': { lat: 14.6349, lng: -90.5069 }, // Guatemala
  'GN': { lat: 9.6412, lng: -13.5784 }, // Guinea
  'GW': { lat: 11.8650, lng: -15.5989 }, // Guinea-Bissau
  'GY': { lat: 6.8013, lng: -58.1551 }, // Guyana
  'HT': { lat: 18.5944, lng: -72.3074 }, // Haiti
  'HN': { lat: 14.0723, lng: -87.1921 }, // Honduras
  'HU': { lat: 47.4979, lng: 19.0402 }, // Hungary
  'IS': { lat: 64.1355, lng: -21.8954 }, // Iceland
  'IN': { lat: 28.6139, lng: 77.2090 }, // India
  'ID': { lat: -6.2088, lng: 106.8456 }, // Indonesia
  'IR': { lat: 35.6892, lng: 51.3890 }, // Iran
  'IQ': { lat: 33.3152, lng: 44.3661 }, // Iraq
  'IE': { lat: 53.3498, lng: -6.2603 }, // Ireland
  'IL': { lat: 31.7683, lng: 35.2137 }, // Israel
  'IT': { lat: 41.9028, lng: 12.4964 }, // Italy
  'JM': { lat: 18.1096, lng: -77.2975 }, // Jamaica
  'JP': { lat: 35.6895, lng: 139.6917 }, // Japan
  'JO': { lat: 31.9454, lng: 35.9284 }, // Jordan
  'KZ': { lat: 51.1694, lng: 71.4491 }, // Kazakhstan
  'KE': { lat: -1.2864, lng: 36.8172 }, // Kenya
  'KI': { lat: -3.3704, lng: -168.7340 }, // Kiribati
  'KP': { lat: 39.0392, lng: 125.7625 }, // North Korea
  'KR': { lat: 37.5665, lng: 126.9780 }, // South Korea
  'KW': { lat: 29.3759, lng: 47.9774 }, // Kuwait
  'KG': { lat: 41.2044, lng: 74.7661 }, // Kyrgyzstan
  'LA': { lat: 17.9757, lng: 102.6331 }, // Laos
  'LV': { lat: 56.9496, lng: 24.1059 }, // Latvia
  'LB': { lat: 33.8547, lng: 35.8623 }, // Lebanon
  'LS': { lat: -29.6094, lng: 28.2336 }, // Lesotho
  'LR': { lat: 6.4281, lng: -9.4295 }, // Liberia
  'LY': { lat: 32.8872, lng: 13.1913 }, // Libya
  'LI': { lat: 47.1415, lng: 9.5215 }, // Liechtenstein
  'LT': { lat: 54.6872, lng: 25.2797 }, // Lithuania
  'LU': { lat: 49.6117, lng: 6.13 }, // Luxembourg
  'MO': { lat: 22.1988, lng: 113.5439 }, // Macau
  'MK': { lat: 41.6086, lng: 21.7453 }, // North Macedonia
  'MG': { lat: -18.7669, lng: 46.8691 }, // Madagascar
  'MW': { lat: -13.2543, lng: 34.3015 }, // Malawi
  'MY': { lat: 3.139, lng: 101.6869 }, // Malaysia
  'MV': { lat: 3.2028, lng: 73.2207 }, // Maldives
  'ML': { lat: 12.6392, lng: -8.0029 }, // Mali
  'MT': { lat: 35.8997, lng: 14.5147 }, // Malta
  'MH': { lat: 7.1304, lng: 171.1845 }, // Marshall Islands
  'MR': { lat: 18.0796, lng: -15.9615 }, // Mauritania
  'MU': { lat: -20.3484, lng: 57.5522 }, // Mauritius
  'MX': { lat: 19.4326, lng: -99.1332 }, // Mexico
  'FM': { lat: 7.4250, lng: 150.5508 }, // Micronesia
  'MD': { lat: 47.0105, lng: 28.8638 }, // Moldova
  'MC': { lat: 43.7333, lng: 7.4167 }, // Monaco
  'MN': { lat: 48.0034, lng: 66.1810 }, // Mongolia
  'ME': { lat: 42.4411, lng: 19.2636 }, // Montenegro
  'MA': { lat: 34.0209, lng: -6.8416 }, // Morocco
  'MZ': { lat: -15.7790, lng: 45.8042 }, // Mozambique
  'MM': { lat: 21.9162, lng: 95.9550 }, // Myanmar
  'NA': { lat: -22.5597, lng: 17.0832 }, // Namibia
  'NR': { lat: -0.5228, lng: 166.9315 }, // Nauru
  'NP': { lat: 27.7172, lng: 85.3240 }, // Nepal
  'NL': { lat: 52.3676, lng: 4.9041 }, // Netherlands
  'NC': { lat: -22.2743, lng: 166.4190 }, // New Caledonia
  'NZ': { lat: -41.2865, lng: 174.7762 }, // New Zealand
  'NI': { lat: 12.8654, lng: -85.2072 }, // Nicaragua
  'NE': { lat: 13.5128, lng: 2.1128 }, // Niger
  'NG': { lat: 9.0820, lng: 8.6753 }, // Nigeria
  'NU': { lat: -19.0330, lng: -169.8680 }, // Niue
  'NF': { lat: -29.0408, lng: 167.9547 }, // Norfolk Island
  'MP': { lat: 14.8058, lng: 145.5274 }, // Northern Mariana Islands
  'NO': { lat: 59.9139, lng: 10.7522 }, // Norway
  'OM': { lat: 23.6140, lng: 58.5903 }, // Oman
  'PK': { lat: 30.3753, lng: 69.3451 }, // Pakistan
  'PW': { lat: 7.5149, lng: 134.5825 }, // Palau
  'PA': { lat: 8.9824, lng: -79.5197 }, // Panama
  'PG': { lat: -6.3149, lng: 143.9555 }, // Papua New Guinea
  'PY': { lat: -23.4420, lng: -58.4438 }, // Paraguay
  'PE': { lat: -12.0464, lng: -77.0428 }, // Peru
  'PH': { lat: 13.4115, lng: 122.5621 }, // Philippines
  'PN': { lat: -24.7030, lng: -127.4390 }, // Pitcairn Islands
  'PL': { lat: 51.9194, lng: 19.1451 }, // Poland
  'PT': { lat: 38.7169, lng: -9.1399 }, // Portugal
  'PR': { lat: 18.4655, lng: -66.1057 }, // Puerto Rico
  'QA': { lat: 25.2760, lng: 51.5200 }, // Qatar
  'RE': { lat: -21.1151, lng: 55.5364 }, // Réunion
  'RO': { lat: 44.4268, lng: 26.1025 }, // Romania
  'RU': { lat: 55.7558, lng: 37.6173 }, // Russia
  'RW': { lat: -1.9403, lng: 29.8739 }, // Rwanda
  'SH': { lat: -15.9650, lng: -5.7089 }, // Saint Helena
  'KN': { lat: 17.3578, lng: -62.7821 }, // Saint Kitts and Nevis
  'LC': { lat: 13.9094, lng: -60.9789 }, // Saint Lucia
  'PM': { lat: 46.8333, lng: -56.3333 }, // Saint Pierre and Miquelon
  'VC': { lat: 13.2528, lng: -61.2000 }, // Saint Vincent and the Grenadines
  'WS': { lat: -13.7590, lng: -172.1046 }, // Samoa
  'SM': { lat: 43.9333, lng: 12.4500 }, // San Marino
  'ST': { lat: 1.8683, lng: 7.4500 }, // São Tomé and Príncipe
  'SA': { lat: 24.7136, lng: 46.6753 }, // Saudi Arabia
  'SN': { lat: 14.6928, lng: -17.4467 }, // Senegal
  'RS': { lat: 44.8176, lng: 20.4633 }, // Serbia
  'SC': { lat: -4.6796, lng: 55.4915 }, // Seychelles
  'SL': { lat: 8.4657, lng: -13.2317 }, // Sierra Leone
  'SG': { lat: 1.3521, lng: 103.8198 }, // Singapore
  'SX': { lat: 18.0420, lng: -63.0551 }, // Sint Maarten
  'SK': { lat: 48.6690, lng: 19.6990 }, // Slovakia
  'SI': { lat: 46.1512, lng: 14.9955 }, // Slovenia
  'SB': { lat: -9.4280, lng: 160.6410 }, // Solomon Islands
  'SO': { lat: 5.1521, lng: 46.1996 }, // Somalia
  'ZA': { lat: -30.5595, lng: 22.9375 }, // South Africa
  'GS': { lat: -54.4296, lng: -36.5879 }, // South Georgia and the South Sandwich Islands
  'SS': { lat: -4.8594, lng: 31.5700 }, // South Sudan
  'ES': { lat: 40.4637, lng: -3.7492 }, // Spain
  'LK': { lat: 7.8731, lng: 80.7718 }, // Sri Lanka
  'SD': { lat: 12.8628, lng: 30.2176 }, // Sudan
  'SR': { lat: 3.9193, lng: -56.0278 }, // Suriname
  'SE': { lat: 60.1282, lng: 18.6435 }, // Sweden
  'CH': { lat: 46.8182, lng: 8.2275 }, // Switzerland
  'SY': { lat: 34.8021, lng: 38.9968 }, // Syria
  'TW': { lat: 23.6978, lng: 120.9605 }, // Taiwan
  'TJ': { lat: 38.8610, lng: 71.2761 }, // Tajikistan
  'TZ': { lat: -6.7924, lng: 39.2083 }, // Tanzania
  'TH': { lat: 15.8700, lng: 100.9925 }, // Thailand
  'TL': { lat: -8.8742, lng: 125.7275 }, // Timor-Leste
  'TG': { lat: 8.6195, lng: 0.8248 }, // Togo
  'TK': { lat: -9.2000, lng: -171.0000 }, // Tokelau
  'TO': { lat: -21.1789, lng: -175.1982 }, // Tonga
  'TT': { lat: 10.6918, lng: -61.2225 }, // Trinidad and Tobago
  'TV': { lat: -7.1095, lng: 177.6493 }, // Tuvalu
  'UG': { lat: 1.3733, lng: 32.2903 }, // Uganda
  'UA': { lat: 48.3794, lng: 31.1656 }, // Ukraine
  'AE': { lat: 23.4241, lng: 53.8478 }, // United Arab Emirates
  'GB': { lat: 55.3781, lng: -3.4360 }, // United Kingdom
  'US': { lat: 37.0902, lng: -95.7129 }, // United States
  'UY': { lat: -32.5228, lng: -55.7659 }, // Uruguay
  'UZ': { lat: 41.3775, lng: 64.5854 }, // Uzbekistan
  'VU': { lat: -15.3767, lng: 166.9591 }, // Vanuatu
  'VE': { lat: 6.4238, lng: -66.5897 }, // Venezuela
  'VN': { lat: 14.0583, lng: 108.2772 }, // Vietnam
  'WF': { lat: -13.7681, lng: -177.9656 }, // Wallis and Futuna
  'EH': { lat: 24.2155, lng: -12.8858 }, // Western Sahara
  'YE': { lat: 15.5520, lng: 48.5164 }, // Yemen
  'ZM': { lat: -13.1339, lng: 27.8493 }, // Zambia
  'ZW': { lat: -19.0154, lng: 29.1549 }, // Zimbabwe
};
