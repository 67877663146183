import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse,
} from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {
  FILE_HOSTING_URL,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES,
  LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES
} from '../constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      req = this.addAuthorizationHeader(req, token);
    }
    return next.handle(req).pipe(
      map((res: any) => {
        if (req.responseType === 'blob' || req.responseType === 'text'
          || req.url.includes('builder/Injector') || req.url.includes('builder/ShellLink') || (req.url.includes(FILE_HOSTING_URL) && req.method === 'POST')) { // todo improve?
          return res;
        }
        return this.authService.handleHttpResponse(res as HttpResponse<any>);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleTokenRefresh(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshInProgress$.value) {
      this.refreshInProgress$.next(true);

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          return next.handle(req).pipe(map((res: any) => {
            return this.authService.handleHttpResponse(res as HttpResponse<any>);
          }));
        }),
        switchMap((response: any) => {
          if (response.succeed) {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.value.accessToken.value || '');
            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, response.value.refreshToken.value || '');
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRES, response.value.accessToken.expires || '');
            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES, response.value.refreshToken.expires || '');
            return next.handle(req);
          } else {
            this.authService.logout();
            return throwError(() => new Error('Token refresh failed'));
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.authService.resetAccountAuthData();
          this.authService.logout();
          return throwError(() => err);
        }),
        finalize(() => {
          this.refreshInProgress$.next(false);
        })
      );
    } else {
      return this.refreshInProgress$.pipe(switchMap(res => {
        return !res ? next.handle(req).pipe(map((res: any) => {
          return this.authService.handleHttpResponse(res as HttpResponse<any>);
        })) : EMPTY;
      }));
    }
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    return request.clone({headers});
  }
}
