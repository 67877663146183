import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {TreeNode} from 'primeng/api';
import {FilesModel} from '../model/machine/file.model';
import {DecodedTokenModel} from '../model/decoded-token.model';
import {ToastService} from './toast.service';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { ProfileModel } from './external/models/profile.model';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '../constants';

@Injectable()
export class UtilService {
  private onPageRefresh = new Subject<boolean>();
  public onPageRefresh$ = this.onPageRefresh.asObservable();
  public userIdChange$ = new Subject<string>();
  public progress$ = new Subject<number>();

  public constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private http: HttpClient,
  ) {
  }

  public getGenders(): { name: string }[] {
    return [
      {name: 'Male'},
      {name: 'Female'},
      {name: 'Other'},
    ];
  }

  public initProfileFormGroup(profileData: ProfileModel): FormGroup {
    const birthDay = profileData.dateOfBirth as string;
    const adjustedBirthDate = this.getAdjustedBirthDate(birthDay);

    return this.fb.group({
      firstName: [profileData.firstName, [Validators.maxLength(50)]],
      lastName: [profileData.lastName, [Validators.maxLength(50)]],
      gender: [profileData.gender],
      address: [profileData.address, [Validators.maxLength(100)]],
      country: [profileData.country],
      city: [profileData.city, [Validators.maxLength(50)]],
      zipCode: [profileData.zipCode, [Validators.maxLength(10)]],
      dateOfBirth: [adjustedBirthDate],
      passportId: [profileData.passportId, [Validators.maxLength(20)]],
    });
  }

  public pageRefresh() {
    this.onPageRefresh.next(true);
  }

  private getAdjustedBirthDate(birthDay: string): Date | null {
    if (birthDay && birthDay !== '0001-01-01T00:00:00') {
      const birthDate = new Date(birthDay);
      birthDate.setDate(birthDate.getDate() + 1);
      return birthDate;
    }
    return null;
  }

  public static getDecodedToken(): DecodedTokenModel | null {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) as string;
      if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
    return null;
  }

  public getCurrentOrganizationId(): string {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) as string;
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(atob(base64)).organizationIdentifier;
    }
    return ''
  }

  public readBlobAsBase64(blob: Blob, unnecessaryPart: string): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const unnecessaryPartIndex = reader.result.indexOf(unnecessaryPart);
          let result;
          if (unnecessaryPartIndex >= 0) {
            result = reader.result.slice(unnecessaryPartIndex + unnecessaryPart.length, reader.result.length);
          } else {
            result = reader.result;
          }
          observer.next(result);
          observer.complete();
        } else {
          observer.error('Failed to read file as Base64');
        }
      };
    });
  }

  public stringToArrayBuffer(str: string): ArrayBuffer {
    const encoder = new TextEncoder();
    return encoder.encode(str).buffer;
  }

  // public buildNode(value: OrganizationModel[]): TreeNode[] {
  //   return value.map(o => {
  //     if (o.organizations?.length) {
  //       return {
  //         label: o.name,
  //         data: o.id,
  //         selectable: true,
  //         leaf: false,
  //         parentId: o.parentId,
  //         children: this.buildNode(o.organizations),
  //       } as TreeNode;
  //     } else {
  //       return {
  //         label: o.name,
  //         data: o.id,
  //         selectable: true,
  //         leaf: true,
  //         parentId: o.parentId,
  //       } as TreeNode;
  //     }
  //   });
  // }

  public buildOrganizationTree(organizations: any[], parentId = ''): TreeNode[] {
    const nodes: TreeNode[] = organizations.map((org: any) => {
      return {
        label: org.name,
        data: org.organizationId,
        children: [],
        leaf: true,
      };
    });
    return nodes;
  }

  public findOrganizationNode(orgId: string, organizationNodes: TreeNode[]): TreeNode | null {
    if (!organizationNodes.length) {
      return null;
    }
    const result = organizationNodes.find(node => node.data === orgId);
    if (result) {
      return result;
    }
    const children: TreeNode[] = [];
    for (const node of organizationNodes) {
      if (node.children) {
        children.push(...node.children);
      }
    }
    return this.findOrganizationNode(orgId, children);
  }

  public getPIconByExtension(file: FilesModel) {
    const extension = file.path.substring(file.path.lastIndexOf('.') + 1, file.path.length);
    let icon;
    switch (extension) {
      case 'txt':
        icon = 'pi pi-file';
        break;
      case 'doc':
        icon = 'pi pi-file-word';
        break;
      case 'jpg':
      case 'png':
        icon = 'pi pi-image';
        break;
      case 'pptx':
      default:
        icon = 'pi pi-file';
        break;
    }
    return icon;
  }

  // public buildOrganizationTree(hierarchicalIds: OrganizationHierarchicalIdsModel[], parentId: string, node?: any,):
  //   Observable<{ childOrgs?: OrganizationModel[], organizationNodes?: TreeNode[] }> {
  //   if (node && hierarchicalIds.length) {
  //     node.leaf = false;
  //   }
  //   const observers = hierarchicalIds.map(org => this.organizationService.getOrganization(org.childId));
  //   observers.push(this.organizationService.getOrganization(parentId));
  //   return zip(observers).pipe(map((organizationsResponses) => {
  //     let parentOrg!: OrganizationModel;
  //     const childOrgs: OrganizationModel[] = [];
  //     organizationsResponses.forEach(orgResponse => {
  //       if (orgResponse.value.id === parentId) {
  //         parentOrg = orgResponse.value;
  //       } else {
  //         childOrgs.push(orgResponse.value);
  //       }
  //     })
  //     parentOrg.organizations = childOrgs;
  //
  //     if (!node) {
  //       return {
  //         organizationNodes: this.buildNode([parentOrg]),
  //       };
  //     } else {
  //       return {
  //         childOrgs,
  //       };
  //     }
  //   }));
  // }

  public showCopied(copyButton: HTMLButtonElement, copiedDiv: HTMLDivElement) {
    copyButton.style.display = 'none';
    copiedDiv.style.display = 'flex';
    setTimeout(() => {
      copyButton.style.display = 'flex';
      copiedDiv.style.display = 'none';
    }, 1000);
  }

  public collapseChildren(node: TreeNode) {
    if (node.children) {
      node.expanded = false;
      for (const cn of node.children) {
        this.collapseChildren(cn);
      }
    }
  }

  public copyText(text: string, toastSummary?: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          if (toastSummary) {
            this.toastService.message$.next({
              severity: 'success',
              summary: toastSummary,
            });
          }
        },
        (err) => {
          console.error('Failed to copy text: ', err);
          if (toastSummary) {
            this.toastService.message$.next({
              severity: 'error',
              summary: 'Failed to copy text. Please try again.',
            });
          }
        }
      );
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = text;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      try {
        document.execCommand('copy');
        if (toastSummary) {
          this.toastService.message$.next({
            severity: 'success',
            summary: toastSummary,
          });
        }
      } catch (err) {
        console.error('Failed to copy text: ', err);
        if (toastSummary) {
          this.toastService.message$.next({
            severity: 'error',
            summary: 'Failed to copy text. Please try again.',
          });
        }
      }
      document.body.removeChild(selBox);
    }
  }

  public getEnumOptions(enumValues: any) {
    return Object.keys(enumValues)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ label: key, value: enumValues[key as keyof typeof enumValues] }));
  }

  public getFileIconClass(file: any | undefined): string {
    if (file.isDirectory) {
      return 'fa fa-folder';
    }
    const extension = file.name.value?.split('.').pop()?.toLowerCase();
    if (!file.name.value || !extension) {
      return 'fa fa-file-code-o';
    }
    return this.fileTypeIconMapping[extension] || 'fa fa-file';
  }

  private fileTypeIconMapping: { [key: string]: string } = {
    'pdf': 'file-icon fa fa-file-pdf-o',
    'doc': 'file-icon fa fa-file-word-o',
    'docx': 'fa fa-file-word-o',
    'xls': 'fa fa-file-excel-o',
    'xlsx': 'fa fa-file-excel-o',
    'ppt': 'fa fa-file-powerpoint-o',
    'pptx': 'fa fa-file-powerpoint-o',
    'jpg': 'file-icon fa fa-file-image-o',
    'jpeg': 'fa fa-file-image-o',
    'png': 'fa fa-file-image-o',
    'gif': 'fa fa-file-image-o',
    'txt': 'fa fa-file-text-o',
    'zip': 'fa fa-file-archive-o',
    'rar': 'fa fa-file-archive-o',
    'exe': 'fa fa-file-code-o',
    'dll': 'fa fa-file-code-o',
    'js': 'fa fa-file-code-o'
  };

  public getPopupResponsiveWidth() {
    if (window.matchMedia('(max-width: 600px)').matches) {
      return '90%';  // Full width for small screens
    } else if (window.matchMedia('(max-width: 1024px)').matches) {
      return '500px';  // Medium width for tablet screens
    } else {
      return '800px';  // Larger width for desktop screens
    }
  }

  public getCurrentOffsetInHours(): number {
    const offsetInMinutes = new Date().getTimezoneOffset();
    return -offsetInMinutes / 60;
  }

   public forbiddenValuesValidator(forbiddenValues: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const value = +control.value;
      return forbiddenValues.includes(value) ? { forbiddenValue: { value } } : null;
    };
  }

  public getFileAsBase64(url: string): Observable<string> {
    return this.http.get(url, {responseType: 'blob'}).pipe(
      switchMap((blob: Blob) => this.readBlobAsBase64(blob, 'data:image/x-icon;base64,'))
    );
  }

  public parseBlobToString(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the blob.'));
      };
      reader.readAsText(blob);
    });
  }
}
