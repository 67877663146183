import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ShellWebSocketService} from '../../../shared/services/shell-web-socket.service';
import {WebSocketService} from '../../../shared/services/web-socket.service';
import {Subject, takeUntil} from 'rxjs';
import { TerminalService } from 'src/app/shared/services/external/terminal.service';

@Component({
  selector: 'app-terminal-shell',
  templateUrl: './terminal-shell.component.html',
  styleUrls: ['./terminal-shell.component.scss'],
  providers: [ShellWebSocketService, WebSocketService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalShellComponent implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();
  private _machineId: string;
  private readonlyTextLength: number;
  private commands: string[] = [];
  private commandIndex: number;
  public disabled = false; // true
  public inNewWindow = false;
  public terminalValue = '';
  public sendData = '';
  public contextMenuVisible = false;
  public contextMenuPosition = {x: 0, y: 0};
  @ViewChild('textareaElement') textareaElement!: ElementRef;

  @Input() public terminalName: string;
  @Input() public visible: boolean;

  get machineId(): string {
    return this._machineId;
  }

  @Input() set machineId(value: string) {
    this._machineId = this._machineId || value;
    if (this._machineId) {
      this.connectWithWebsocket();
      this.handleReceivedFrame();
    }
  }

  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    private shellWebSocketService: ShellWebSocketService,
    private route: ActivatedRoute,
    private terminalService: TerminalService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params['machineId']) {
        this.machineId = params['machineId'] as string;
        this.inNewWindow = true;
        this.connectWithWebsocket();
        this.handleReceivedFrame();
      }
    });
  }

  public sendMessage() {
    // this.disabled = true;
    const data = this.terminalValue.substring(this.readonlyTextLength);
    // console.log('data: ' + data );
    this.commands.push(data);
    this.commandIndex = this.commands.length - 1;
    this.shellWebSocketService.sendMessage(data);
  }

  public onKeyDown(event: any): void {
    const selectionStart = event.target['selectionStart'];

    if (this.isAtReadonlyStart(selectionStart, event.key)) {
      event.preventDefault();
      return;
    }

    if (event.key === 'ArrowUp') {
      this.handleArrowUp(event);
    } else if (event.key === 'ArrowDown') {
      this.handleArrowDown(event);
    }
  }

  private isAtReadonlyStart(selectionStart: number, key: string): boolean {
    return (selectionStart === this.readonlyTextLength && (key === 'Backspace' || key === 'ArrowLeft')) || selectionStart < this.readonlyTextLength;
  }

  private handleArrowUp(event: KeyboardEvent): void {
    if (this.commands.length) {
      if (this.commandIndex === this.commands.length) {
        this.commandIndex--;
      }

      if (this.commandIndex === -1) {
        event.preventDefault();
        return;
      }

      if (this.commandIndex === undefined) {
        this.commandIndex = this.commands.length - 1;
      }

      this.updateTerminalValue();
      this.commandIndex--;
      event.preventDefault();
    }
  }

  private handleArrowDown(event: KeyboardEvent): void {
    if (this.commands.length) {
      if (this.commandIndex === -1) {
        this.commandIndex++;
      }

      if (this.commandIndex === this.commands.length) {
        this.terminalValue = this.terminalValue.slice(0, this.readonlyTextLength);
      } else if (this.commandIndex !== undefined) {
        this.updateTerminalValue();
        this.commandIndex++;
      }

      event.preventDefault();
    }
  }

  private updateTerminalValue(): void {
    this.terminalValue = this.terminalValue.slice(0, this.readonlyTextLength);
    this.terminalValue += this.commands[this.commandIndex] || '';
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!event.target || !(event.target as HTMLElement).closest('.context-menu')) {
      this.contextMenuVisible = false;
      this.cdr.detectChanges();
    }
  }

  public onContextmenu(event: any): void {
    event.preventDefault();
    this.contextMenuVisible = true;
    this.contextMenuPosition = {x: event.layerX, y: event.layerY};
    this.cdr.detectChanges();
  }

  public pasteText(): void {
    navigator.clipboard.readText().then((clipboardText) => {
      this.sendData += clipboardText;
      this.contextMenuVisible = false;
      this.cdr.detectChanges();
    }).catch((error) => {
      console.error('Failed to read clipboard contents: ', error);
    });
  }

  public cutText(): void {
    navigator.clipboard.writeText(this.sendData).then(() => {
      this.sendData = '';
      this.contextMenuVisible = false;
      this.cdr.detectChanges();
    }).catch((error) => {
      console.error('Failed to write clipboard contents: ', error);
    });
  }

  public deleteText(): void {
    this.sendData = '';
    this.contextMenuVisible = false;
    this.cdr.detectChanges();
  }

  public openNewWindows() {
    const popupOptions = 'width=600,height=400';
    window.open(`https://${document.domain}/terminal-shell/${this.machineId}`, '_blank', popupOptions);
    // window.open(`http://localhost:4201/terminal-shell/${this.machineId}`, '_blank', popupOptions);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.shellWebSocketService.closeSocket();
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.onClose.emit(true);
    }
  }

  public downloadCurrentState(): void {
    const blob = new Blob([this.terminalValue], {type: 'text/plain'});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    const now = new Date();
    const dateString = this.formatDate(now);
    a.download = `${this.terminalName}.shell.${dateString}.txt`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padNumber(date.getMonth() + 1);
    const day = this.padNumber(date.getDate());
    const hours = this.padNumber(date.getHours());
    const minutes = this.padNumber(date.getMinutes());
    const seconds = this.padNumber(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }

  private connectWithWebsocket() {
    this.terminalService.getTerminalShell(this.machineId).subscribe(res => {
      if (res.succeed) {
        this.shellWebSocketService.connectWithWebsocket(res.value);
      }
    })
  }

  private handleReceivedFrame(): void {
    this.shellWebSocketService.rawDataReceived$.subscribe({
      next: ({dataView, endOfMessage}) => {
        // this.disabled = false;
        this.sendData = '';
        const textDecoder = new TextDecoder('utf-8');
        const decodedString = textDecoder.decode(dataView);
        const normalizedString = decodedString.replace(/\r\n|\r/g, '\n');
        this.terminalValue += normalizedString;
        this.cdr.detectChanges();
        this.readonlyTextLength = this.terminalValue.length;

        setTimeout(() => {
          const textarea = this.textareaElement.nativeElement;
          // this.readonlyTextLength = textarea.value.length;
          textarea.scrollTop = textarea.scrollHeight;
          this.cdr.detectChanges();
        }, 0)
      },
      error: (err: string) => {
        console.error(err);
      },
    });
  }
}
