import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TableFieldModel} from '../../model/table-field.model';
import {DEFAULT_TABLE_LIMIT, DEFAULT_TABLE_ROWS_OPTIONS} from '../../constants';
import {MenuItem} from 'primeng/api';
import {TableLazyLoadEvent, TablePageEvent, TableRowSelectEvent} from 'primeng/table/table.interface';
import {FieldTypesEnum} from '../../field-types.enum';
import {UtilService} from '../../services/util.service';
import {Table} from 'primeng/table';
import {CheckboxChangeEvent} from 'primeng/checkbox';
import { SyncStatusEnum } from '../../services/external/models/sync-status.enum';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  public globalFilterField: string[] = [];
  public filterValue = '';
  public globalFilterPlaceholder = '';
  public offset: number;
  public columnFilterVisible: any = {};
  public fieldTypesEnum = FieldTypesEnum;
  public loadingRows: number[] = [];
  public virtualScrollOptions: any;
  public selectedRowsCount = 0;
  public allSelected = false;
  private allRowsCount: number;
  private _fields: TableFieldModel[] = [];

  private _value: any[];
  @Input() public highlightRecentlyCreated = false;
  @Input() public menuItems: MenuItem[] = [];
  @Input() public contextMenuItems: MenuItem[] = [];
  @Input() public selectedItem: any;
  @Output() public selectedItemChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() public hasMenuPermission = false;
  @Input() public lazy = false;
  @Input() public paginator = false;
  @Input() public loading = false;
  @Input() public showCurrentPageReport = false;
  @Input() public filterDelay = 0;
  @Input() public globalFilterMatchMode = 'contains';
  @Input() public columnFilterMatchMode = 'contains';
  @Input() public rowsLimit = DEFAULT_TABLE_LIMIT;
  @Input() public rowsPerPageOptions = DEFAULT_TABLE_ROWS_OPTIONS;
  @Input() public totalRecords: number;
  @Input() public content: string;
  @Input() public secondaryContent: string;
  @Input() public additionalFields: TableFieldModel[];
  @Input() public selectionMode: 'multiple' | 'single';
  @Output() public onRowSelect$: EventEmitter<TableRowSelectEvent> = new EventEmitter<TableRowSelectEvent>();
  @Output() public onRowClick$: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRowDataChange$: EventEmitter<{ row: any; event: CheckboxChangeEvent, data: any[] }> = new EventEmitter<{ row: any; event: CheckboxChangeEvent, data: any[] }>();
  @Output() public onLoad$: EventEmitter<{ first: number, last: number }> = new EventEmitter<{ first: number, last: number }>();
  @Output() public onLazyLoad$: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();
  @Output() public onPage$: EventEmitter<TablePageEvent> = new EventEmitter<TablePageEvent>();
  @Output() public onToggleMenu$: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onAction$: EventEmitter<TableRowSelectEvent> = new EventEmitter<TableRowSelectEvent>();
  @Input() public first = 0;
  @Input() public virtualScroll = false;
  @ViewChild('table') public primeTableComponent: Table;
  @ViewChild('cm') contextMenu: any;

  showContextMenu(event: TouchEvent) {
    const rowElement = event.target as HTMLElement;
    rowElement.style.userSelect = 'text';
    rowElement.style.cursor = 'text';

    this.contextMenu.show(event);
    event.preventDefault();
  }
  public get value(): any[] {
    return this._value;
  }

  @Input()
  set fileIsSelected(_: boolean) {
    if (this.selectedItem) {
      this.selectedItem['userIsSelected'] = true;
      this.changeRowData(this.selectedItem, {
        checked: true,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  @Input()
  public set value(value: any[]) {
    this.allSelected = false;
    this.selectedRowsCount = 0;
    if (!this.value || !this.value.length) {
      this.virtualScrollOptions = {
        items: value,
        step: 40,
        orientation: 'vertical',
        autoSize: true,
        numToleratedItems: 20, // intervals of firsts/lasts is numToleratedItems+1
        delay: 0,
        lazy: false,
        onScrollIndexChange: this.onLoad.bind(this),
      };
    }
    this._value = value;
    this.calculateLoadingRowsCount();
  }

  @Input()
  set fields(value: TableFieldModel[]) {
    this._fields = value;
    this.globalFilterField = value.filter(field => field.globalFilter).map(field => field.name);
    this.globalFilterPlaceholder = value.filter(field => field.globalFilter).map(field => field.displayName).join(', ');
  }

  get fields(): TableFieldModel[] {
    return this._fields;
  }

  @Input()
  set rowsCount(allRowsCount: number) {
    this.allRowsCount = allRowsCount;
    if (allRowsCount) {
      this.calculateLoadingRowsCount();
    }
  }

  private calculateLoadingRowsCount() {
    this.loadingRows = [];
    if (this.allRowsCount) {
      for (let i = 0; i < this.allRowsCount - this.value.length; i++) {
        this.loadingRows.push(i);
      }
    }
    this.cdr.detectChanges();
  }

  public constructor(
    public cdr: ChangeDetectorRef,
    public utilService: UtilService,
  ) {
    this.offset = this.utilService.getCurrentOffsetInHours();
  }

  public onLoad(e: any) {
    this.onLoad$.emit(e);
  }

  public onLazyLoadEvent(event: TableLazyLoadEvent) {
    this.onLazyLoad$.emit(event);
  }

  public toggleMenu(menu: any, event: MouseEvent, row: TableRowSelectEvent) {
    menu.toggle(event);
    this.onToggleMenu$.emit(row);
  }

  public onAction(row: TableRowSelectEvent) {
    this.onAction$.emit(row);
  }

  public resetFilter(fieldName: string) {
    this.columnFilterVisible[fieldName] = false;
  }

  public onPageEvent(event: TablePageEvent) {
    this.onPage$.emit(event);
  }

  public onRowSelectEvent(event: TableRowSelectEvent) {
    this.onRowSelect$.emit(event);
  }

  public changeRowData(row: TableRowSelectEvent, event: CheckboxChangeEvent) {
    if (event.checked) {
      this.selectedRowsCount++;
    } else {
      this.selectedRowsCount--;
    }
    this.onRowDataChange$.emit({row, event, data: this.value});
  }

  public resetScrollTop() {
    this.primeTableComponent.resetScrollTop();
  }

  protected readonly SyncStatusEnum = SyncStatusEnum;

  public onContextMenuSelectionChange(selectedItem: any) {
    this.selectedItem = selectedItem;
    this.selectedItemChange.emit(this.selectedItem);
  }

  public onRowClick(row: any) {
    this.onRowClick$.emit(row);
  }

  public changeAllRowsData(event: CheckboxChangeEvent) {
    for (const v of this.value) {
      if (v['userIsSelected'] === event.checked) {
        if (event.checked) {
          this.selectedRowsCount--;
        } else {
          this.selectedRowsCount++;
        }
      }
      v['userIsSelected'] = event.checked;
      this.changeRowData(v, {
        checked: event.checked,
      });
    }
  }
}
