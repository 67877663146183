<ng-container *ngIf="isPreviewModeOpened; else onlyExplorer">
  <p-splitter [panelSizes]="[55, 45]" styleClass="mb-5">
    <ng-template pTemplate>
      <ng-container *ngTemplateOutlet="onlyExplorer"></ng-container>
    </ng-template>
    <ng-template pTemplate>
      <app-file-services-preview
        [machineId]="machineId"
        [previewInSplitter]="isPreviewModeOpened"
        (previewInSplitterChange)="previewInSplitterChange($event)"
      ></app-file-services-preview>
    </ng-template>
  </p-splitter>
</ng-container>

<!--<app-file-services-preview-->
<!--  *ngIf="!previewInSplitter"-->
<!--  [machineId]="machineId"-->
<!--  [previewInSplitter]="false"-->
<!--  (previewInSplitterChange)="previewInSplitterChange($event)"-->
<!--&gt;</app-file-services-preview>-->

<ng-template #onlyExplorer>
  <ng-container *ngIf="selectedIconSize.code == 'details'">

    <div style="box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);" class="files-table">
      <app-table
        #filesTableComponent
        [value]="files"
        [fields]="fileFields"
        [additionalFields]="additionalFieldsForFiles"
        (onRowDataChange$)="onRowDataChange($event)"
        [hasMenuPermission]="false"
        [lazy]="true"
        [totalRecords]="totalRecords"
        [paginator]="false"
        [first]="first"
        [loading]="loading"
        [rowsLimit]="+storedLimit"
        [fileIsSelected]="fileIsSelected"
        (onLoad$)="emitFileLoad($event)"
        (onLazyLoad$)="emitFilesLazyLoad($event)"
        [contextMenuItems]="isRegisterEditor ? contextMenuItemsForRegEdit : contextMenuItems"
        [(selectedItem)]="selectedFile"
        (selectedItemChange)="initCmItems()"
        (onRowClick$)="onRowClick($event)"
      ></app-table>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedIconSize.code !== 'details'">
    <app-files-thumbnail-viewer
      [contextMenuItems]="contextMenuItems"
      [(selectedFile)]="selectedFile"
      (selectedFileChange)="initCmItems()"
      [loading]="loading"
      [currentPath]="currentPath"
      [currentNode]="currentNode"
      [filesShownInExplorer]="files"
      [selectedIconSize]="selectedIconSize"
      (onLazyLoad$)="emitFilesLazyLoad($event)"
      (selectedNodeInPath)="selectedNodeInPath.emit($event)"
      (selectedFileChange$)="nodeSelect($event)"
    ></app-files-thumbnail-viewer>
  </ng-container>
</ng-template>
