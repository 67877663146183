import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {FileEventService} from '../file-event.service';
import {ActivatedRoute} from '@angular/router';
import {
  FILE_VALIDATION_MESSAGE,
  IMAGE_EXTENSIONS,
  MAX_FILE_SIZE_FOR_PREVIEW,
  TEXT_EXTENSIONS
} from '../../../../shared/constants';
import {Subscription} from 'rxjs';
import { FileServiceService } from 'src/app/shared/services/external/file-service.service';

@Component({
  selector: 'app-file-services-preview',
  templateUrl: './file-services-preview.component.html',
  styleUrls: ['./file-services-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileServicesPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public machineId: string;
  @Input() public previewInSplitter: boolean;
  @Output() public previewInSplitterChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public path: string;
  public separateWindow = false;
  public preview = false;
  public previewHeader = '';
  private sub: Subscription;

  public constructor(
    private fileService: FileServiceService,
    private cdr: ChangeDetectorRef,
    private fileEventService: FileEventService,
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['machineId'] && params['path']) {
        this.separateWindow = true;
        this.cdr.detectChanges();
        !this.machineId && (this.machineId = params['machineId'] as string);
        !this.path && (this.path = (params['path'] as string).replaceAll('-', String.raw`\\`));
        const extension = this.fileEventService.getFileExtension(this.path);
        // this.initFileView(extension);
      } else {
        this.sub = this.fileEventService.onFileViewSource.subscribe((node) => {
          this.onFileView(node);
        });
      }
    });
  }

  ngAfterViewInit() {
    this.displayValidationMessage();
  }

  public onHideDialog() {
    const viewEl: HTMLElement = document.getElementById('view')!;
    viewEl.innerHTML = '';
  }

  public showPreviewDialog() {
    this.preview = true;
    this.cdr.detectChanges();
  }

  public onFileView(node: TreeNode): void {
    if ((node as any).isFile) {
      this.showFile(node);
    }
  }

  private showFile(node: TreeNode) {
    this.path = node.data;
    this.previewHeader = (node as any).name.value!;
    this.initFileView(node);
  }

  private initFileView(node: any) {
    if (!this.separateWindow) {
      this.showPreviewDialog();
    }
    this.fileService.downloadFile(this.machineId, node.id).subscribe({
      next: response => {
        const extension = this.fileEventService.getFileExtension(node.name.value);
        const blob = new Blob([response.body!]);
        if (node.fileSize.value < MAX_FILE_SIZE_FOR_PREVIEW) {
          this.displayValidationMessage();
          return;
        }
        if (IMAGE_EXTENSIONS.map(ext => ext.toLowerCase()).includes(extension.toLowerCase())) {
          this.displayImageFile(blob);
          return;
        } else if (TEXT_EXTENSIONS.map(ext => ext.toLowerCase()).includes(extension.toLowerCase())) {
          this.displayTextFile(blob);
          return;
        }
        this.displayValidationMessage();
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error('Error downloading file:', error);
      }
    });
  }

  private displayValidationMessage() {
    const viewEl: HTMLElement = document.getElementById('view')!;
    const textElement = document.createElement('p');
    textElement.innerText = FILE_VALIDATION_MESSAGE;
    textElement.style.padding = '20px';
    textElement.style.textAlign = 'center';
    textElement.style.marginTop = '28%';
    viewEl.innerHTML = '';
    viewEl.appendChild(textElement);
  }

  private displayTextFile(blob: Blob): void {
    const viewEl: HTMLElement = document.getElementById('view')!;
    const textElement = document.createElement('pre');
    const reader = new FileReader();
    reader.onload = () => {
      textElement.innerText = reader.result as string;
      viewEl.innerHTML = '';
      viewEl.appendChild(textElement);
    };
    reader.readAsText(blob);
  }

  private displayImageFile(blob: Blob): void {
    const viewEl: HTMLElement = document.getElementById('view')!;
    const imageElement = document.createElement('img');
    const reader = new FileReader();

    reader.onload = () => {
      imageElement.src = reader.result as string;
      imageElement.style.width = '100%';
      viewEl.innerHTML = '';
      viewEl.appendChild(imageElement);
    };
    reader.readAsDataURL(blob);
  }

  public openSeparateWindow() {
    const popupOptions = 'width=600,height=400';
    const pathCoded = this.path.replaceAll('\\', '-');
    window.open(`https://${document.domain}/file/${this.machineId}/${pathCoded}`, '_blank', popupOptions);
    // window.open(`http://localhost:4201/file/${this.machineId}/${pathCoded}`, '_blank', popupOptions);
  }

  public openPreviewInSplitter() {
    this.previewInSplitter = true;
    this.previewInSplitterChange.emit(true);
  }

  public openPreviewInPopup() {
    this.previewInSplitter = false;
    this.previewInSplitterChange.emit(false);
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
