import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {LeftBarComponent} from './components/left-bar/left-bar.component';
import {CommonModule} from '@angular/common';
import {SidebarModule} from 'primeng/sidebar';
import {DrawerComponent} from './components/drawer/drawer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyFilterModule} from 'primeng/keyfilter';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ImageModule} from 'primeng/image';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableComponent} from './components/table/table.component';
import {MenuModule} from 'primeng/menu';
import {TreeSelectModule} from 'primeng/treeselect';
import {FieldsetModule} from 'primeng/fieldset';
import {SkeletonModule} from 'primeng/skeleton';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {DeletePopup} from './components/delete-popup/delete-popup.component';
import {IconSizeDropdownComponent} from './components/icon-size-dropdown/icon-size-dropdown.component';
import {AddressPopupComponent} from './components/address-popup/address-popup.component';
import {EmailComponent} from './components/email-popup/email.component';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TreeTableModule} from 'primeng/treetable';
import {FileStateIconPipe} from '../file-state.pipe';
import {SplitterModule} from 'primeng/splitter';
import {AutoScrollDirective} from './directives/auto-scroll.directive';
import {PartialDisableDirective} from './directives/partial-disable.directive';
import {ContextMenuModule} from 'primeng/contextmenu';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {DateTimezonePipe} from './pipes/date-timezone.pipe';
import {ByteNormalizerPipe} from './pipes/byte-normalizer.pipe';
import {LongPressDirective} from './directives/long-press.directive';
import {CanvasJSAngularChartsModule} from '@canvasjs/angular-charts';
import {ProgressBar} from './components/progress/progress-bar.component';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {UserSessionsComponent} from '../modules/identity/users/user-sessions/user-sessions.component';
import {TimeDiffPipe} from './pipes/time-diff.pipe';

@NgModule({
  declarations: [
    ProgressBar,
    HeaderComponent,
    LeftBarComponent,
    DrawerComponent,
    ByteNormalizerPipe,
    DateTimezonePipe,
    TimeAgoPipe,
    TimeDiffPipe,
    TableComponent,
    DeletePopup,
    AddressPopupComponent,
    EmailComponent,
    IconSizeDropdownComponent,
    FileStateIconPipe,
    AutoScrollDirective,
    PartialDisableDirective,
    LongPressDirective,
    UserSessionsComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AccordionModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    KeyFilterModule,
    SplitButtonModule,
    ImageModule,
    CardModule,
    TabViewModule,
    DividerModule,
    CheckboxModule,
    MultiSelectModule,
    MenuModule,
    TreeSelectModule,
    FieldsetModule,
    SkeletonModule,
    TieredMenuModule,
    TooltipModule,
    ProgressSpinnerModule,
    TreeTableModule,
    SplitterModule,
    ContextMenuModule,
    MenubarModule,
    PanelMenuModule,
    CanvasJSAngularChartsModule
  ],
    exports: [
        HeaderComponent,
        LeftBarComponent,
        DrawerComponent,
        ByteNormalizerPipe,
        DateTimezonePipe,
        TableComponent,
        IconSizeDropdownComponent,
        AutoScrollDirective,
        PartialDisableDirective,
        UserSessionsComponent,
        ProgressBar
    ],
  providers: []
})
export class SharedModule {
}
