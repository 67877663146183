<p-sidebar
  [visible]="true"
  [modal]="false"
  [showCloseIcon]="false"
  [styleClass]="onlyIcons ? 'sidebar-only-icons' : 'sidebar-full'"
  [style]="{width: onlyIcons ? LEFT_BAR_WIDTH_ICONS_ONLY : LEFT_BAR_WIDTH, top: '50px',
  transition: 'width 150ms cubic-bezier(0, 0, 0.2, 1)', borderTop: 0,}"
  position="left">

  <ng-template pTemplate="content">
    <ul class="navigation no-user-selection">

      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.dashboard }" (click)="openHome()" *ngIf="hasAccessMachines">
        <a href="/home" (click)="$event.preventDefault()"><i class="fa fa-tachometer navigation-img"></i></a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/home">Dashboard</a>
      </li>
      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.terminals }" (click)="openTerminals()" *ngIf="hasAccessMachines">
        <a href="/terminals" (click)="$event.preventDefault()"><i class="fa fa-desktop navigation-img"></i></a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/terminals">Terminals</a>
      </li>
      <li style="width: 100%; margin: 1px 0">
        <div style="width: 100%;" class="flex align-items-centercursor-pointer">
          <p-panelMenu [model]="itemsForIdentity" [styleClass]="onlyIcons ? 'sidebar-only-icons' : 'sidebar-full'">
            <ng-template pTemplate="item" let-item>
              <a [ngClass]="{ 'selected-item': selectedItem === item.enumKey,}"
                 *ngIf="item.href" style="gap: 10px;" (click)="$event.preventDefault()" [href]="item.href"
                 class="flex align-items-start py-1 cursor-pointer sub-item">
                <ng-container *ngTemplateOutlet="panelMenuItem"></ng-container>
              </a>
              <div *ngIf="!item.href" style="gap: 10px;" class="flex align-items-start px-0 py-0 cursor-pointer">
                <ng-container *ngTemplateOutlet="panelMenuItem"></ng-container>
              </div>
              <ng-template #panelMenuItem>
                <i [class]="item.icon + ' navigation-img'"></i>
                <div class="text">
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-template>
          </p-panelMenu>
        </div>
      </li>

      <li style="width: 100%; margin: 1px 0">
        <div style="width: 100%;" class="flex align-items-center  cursor-pointer"  >
          <p-panelMenu [model]="itemsForAdministration"
                       [styleClass]="onlyIcons ? 'sidebar-only-icons' : 'sidebar-full'">
            <ng-template pTemplate="item" let-item>
              <a [ngClass]="{ 'selected-item': selectedItem === item.enumKey}"
                 *ngIf="item.href" style="gap: 10px;" (click)="$event.preventDefault()" [href]="item.href"
                 class="flex align-items-start py-1 cursor-pointer sub-item">
                <ng-container *ngTemplateOutlet="panelMenuItem"></ng-container>
              </a>
              <div *ngIf="!item.href" style="gap: 10px;" class="flex align-items-start px-0 py-0 cursor-pointer">
                <ng-container *ngTemplateOutlet="panelMenuItem"></ng-container>
              </div>

              <ng-template #panelMenuItem>
                <i [class]="item.icon + ' navigation-img'"></i>
                <div class="text">
                  {{ item.label }}
                </div>
              </ng-template>
            </ng-template>
          </p-panelMenu>
        </div>
      </li>

      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.builder }" (click)="openBuilder()" *ngIf="hasAccessBuilder" class="builder_item">
        <a href="/builder" (click)="$event.preventDefault()" routerLink="/home">
          <!--          <i class="pi pi-wrench navigation-img"></i>-->
          <img width="25px" [src]="isDarkMode ? 'assets/icons/build-dark.png' : 'assets/icons/build.png'"
               class="navigation-img" style="margin-bottom: 0;">
        </a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/builder">Builder</a>
      </li>

      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.fileHosting }" (click)="openFileHosting()" *ngIf="hasAccessFileHosting">
        <a href="/administration/file-hosting" (click)="$event.preventDefault()">
          <i class="fa fa-file-text navigation-img"></i>
        </a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/file-hosting">File
          Hosting</a>
      </li>

      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.urlRedirections }" (click)="openUrlAdministrations()" *ngIf="hasAccessUrlAdministration">
        <a href="/url-redirection" (click)="$event.preventDefault()">
          <i class="fa-solid fa-shuffle navigation-img"></i>
        </a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/url-redirection">URL
          Redirections</a>
      </li>

      <li [ngClass]="{ 'selected-item': selectedItem === LeftBarItemsEnum.urlShortener }" (click)="openUrlShortener()">
        <a href="/url-shortener" (click)="$event.preventDefault()"><i class="pi pi-link navigation-img"></i></a>
        <a *ngIf="!onlyIcons" (click)="$event.preventDefault()" class="text" href="/url-shortener">URL Shortener</a>
      </li>

    </ul>
  </ng-template>


  <!--  <p-panelMenu [model]="items" [styleClass]="onlyIcons ? 'sidebar-only-icons' : 'sidebar-full'">-->
  <!--    <ng-template pTemplate="item" let-item>-->
  <!--      <a class="flex align-items-center px-3 py-2 cursor-pointer" routerLink="/home" target="_blank">-->
  <!--        <i [class]="item.icon + ' text-primary'"></i>-->
  <!--        <a class="ml-2" routerLink="/home">-->
  <!--          {{ item.label }}-->
  <!--        </a>-->
  <!--      </a>-->
  <!--    </ng-template>-->
  <!--  </p-panelMenu>-->

</p-sidebar>
