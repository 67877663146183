import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Response} from '../../model/response.model';
import {API_URL, SKIP_LOADING} from '../../constants';
import {HostedServiceModel} from '../../model/hosted-services/hosted-service.model';
import {JobModel} from '../../model/hosted-services/job.model';
import {MailModel} from '../../model/mailbox/mail.model';
import {FilesModel} from '../../model/machine/file.model';
import {UserMachineAccessModel} from '../../model/machine/user-machine-access.model';
import {MachineActivityLogModel, MachineModel,} from '../../model/machine/machine.model';
import {PersonalDataModel} from '../../model/user/personal-data.model';
import {CloneUrlAdministrationModel} from '../../model/clone-url-administration.model';
import {UrlAdministration} from '../../model/url-administration/url-administration';
import {UserPersonalDataAccessModel} from '../../model/employee/user-personal-data-access.model';


@Injectable()
export class HttpDataService {

  private readonly headersSkippingLoading: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingLoading: object;
  private readonly options: object;

  private static readonly urlHostedServices = `${API_URL}/hostedServices/`;
  private static readonly urlMail = `${API_URL}/mail/`;
  private static readonly urlMachines = `${API_URL}/machines/`;
  private static readonly urlFileSystem = `${API_URL}/fileSystem/`;
  private static readonly urlSyncManager = `${API_URL}/SyncManager`;
  private static readonly urlAdministrations = `${API_URL}/UrlAdministrations/`;
  private static readonly urlOrganizationMachines = `${API_URL}/OrganizationMachines/`;
  private static readonly urlOrganizationPersonalData = `${API_URL}/OrganizationPersonalData/`;

  public constructor(private http: HttpClient) {
    this.headersSkippingLoading = new HttpHeaders({
      'Content-Type': 'application/json',
      [SKIP_LOADING]: 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.optionsSkippingLoading = {
      headers: this.headersSkippingLoading,
      withCredentials: true,
      params: new HttpParams(),
    };
    this.options = {
      headers: this.headers,
      withCredentials: true,
      params: new HttpParams(),
    };
  }

  // HostedServices
  public getHostedServices(): Observable<Response<HostedServiceModel[]>> {
    return this.http.get<Response<HostedServiceModel[]>>(`${HttpDataService.urlHostedServices}`, this.optionsSkippingLoading);
  }

  public getHostedService(serviceName: string): Observable<Response<HostedServiceModel>> {
    return this.http.get<Response<HostedServiceModel>>(`${HttpDataService.urlHostedServices}${serviceName}`, this.options);
  }

  public resetHostedServiceJobStatus(serviceName: string, id: string): Observable<Response<HostedServiceModel>> {
    return this.http.post<Response<HostedServiceModel>>(`${HttpDataService.urlHostedServices}${serviceName}/${id}`, {}, this.options);
  }

  public getHostedServiceJobs(serviceName: string): Observable<Response<JobModel[]>> {
    return this.http.get<Response<JobModel[]>>(`${HttpDataService.urlHostedServices}${serviceName}/jobs`, this.options);
  }

  public startHostedService(serviceName: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(`${HttpDataService.urlHostedServices}${serviceName}/start`, this.options);
  }

  public stopHostedService(serviceName: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(`${HttpDataService.urlHostedServices}${serviceName}/stop`, this.options);
  }

  // Mail
  public getMails(): Observable<Response<MailModel[]>> {
    return this.http.get<Response<MailModel[]>>(`${HttpDataService.urlMail}`, this.options);
  }

  public deleteMails(): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlMail}`, this.options);
  }

  public deleteMail(id: number): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${HttpDataService.urlMail}${id}`, this.options);
  }

  // Machines
  public getMachineByMachineId(machineId: string): Observable<Response<MachineModel>> {
    return this.http.get<Response<MachineModel>>(`${HttpDataService.urlMachines}${machineId}`, this.optionsSkippingLoading);
  }

  public getSoftRestartMachine(machineId: string): Observable<Response<boolean>> {
    return this.http.get<Response<boolean>>(`${HttpDataService.urlMachines}${machineId}/softRestart`, this.optionsSkippingLoading);
  }

  public getAllMachinesCount(): Observable<Response<number>> {
    return this.http.get<Response<number>>(`${HttpDataService.urlMachines}count`, this.optionsSkippingLoading);
  }

  public getOnlineMachinesCount(): Observable<Response<number>> {
    return this.http.get<Response<number>>(`${HttpDataService.urlMachines}online/count`, this.optionsSkippingLoading);
  }

  public getAllMachines(skip: number, take: number): Observable<Response<MachineModel[]>> {
    return this.http.get<Response<MachineModel[]>>(`${HttpDataService.urlMachines}${skip}/${take}`, this.optionsSkippingLoading);
  }

  public getOnlineMachines(skip: number, take: number): Observable<Response<MachineModel[]>> {
    return this.http.get<Response<MachineModel[]>>(`${HttpDataService.urlMachines}online/${skip}/${take}`, this.optionsSkippingLoading);
  }

  public getMachineActivityLogs(machineId: string, skip: number, take: number): Observable<Response<MachineActivityLogModel[]>> {
    return this.http.get<Response<MachineActivityLogModel[]>>(`${HttpDataService.urlMachines}${machineId}/activityLogs/${skip}/${take}`,
      this.optionsSkippingLoading);
  }

  public createMachineUserWithMachineId(body: PersonalDataModel, machineId: string): Observable<Response<string>> {
    return this.http.put<Response<string>>(`${HttpDataService.urlMachines}${machineId}/personalData`, body, this.optionsSkippingLoading);
  }

  public resetSyncStatus(machineId: string, syncDataId: string): Observable<Response<null>> {
    return this.http.get<Response<null>>(
      `${HttpDataService.urlSyncManager}/${machineId}/${syncDataId}/reset`, this.optionsSkippingLoading);
  }

  // File System
  public getFilesFilteredByDate(machineId: string, from: string, to: string): Observable<Response<FilesModel[]>> {
    return this.http.get<Response<FilesModel[]>>(
      `${HttpDataService.urlFileSystem}filterFilesByDateTime/${machineId}/${from}/${to}`, this.optionsSkippingLoading);
  }

  // Builder
  public cloneUrlAdministration(body: CloneUrlAdministrationModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${HttpDataService.urlAdministrations}clone`, body, this.optionsSkippingLoading);
  }

  // UrlAdministrations
  public getUrlAdministrations(personalDataId: string, skip: number, take: number): Observable<Response<UrlAdministration[]>> {
    return this.http.get<Response<UrlAdministration[]>>(`${HttpDataService.urlAdministrations}${personalDataId}/${skip}/${take}`, this.optionsSkippingLoading);
  }

  //OrganizationMachines
  public getAllMachinesByOrganizationId(organizationId: string): Observable<Response<string[]>> {
    return this.http.get<Response<string[]>>(`${HttpDataService.urlOrganizationMachines}Organizations/${organizationId}`, this.optionsSkippingLoading);
  }

  public createOrganizationMachineAccessPermission(body: UserMachineAccessModel): Observable<Response<void>> {
    return this.http.post<Response<void>>(`${HttpDataService.urlOrganizationMachines}`, body, this.optionsSkippingLoading);
  }

  public deleteOrganizationMachineAccessPermission(machineId: string, organizationId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${HttpDataService.urlOrganizationMachines}${machineId}/${organizationId}`, {
      ...this.optionsSkippingLoading,
    });
  }

  //OrganizationPersonalData
  public createOrganizationPersonalDataAccessPermission(body: UserPersonalDataAccessModel): Observable<Response<void>> {
    return this.http.post<Response<void>>(`${HttpDataService.urlOrganizationPersonalData}`, body, this.optionsSkippingLoading);
  }

  public deleteOrganizationPersonalDataAccessPermission(personalDataId: string, organizationId: string): Observable<Response<void>> {
    return this.http.delete<Response<void>>(`${HttpDataService.urlOrganizationPersonalData}${personalDataId}/${organizationId}`, {
      ...this.optionsSkippingLoading,
    });
  }
}
