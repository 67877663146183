import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {WebSocketService} from './web-socket.service';
import {API_URL, WS_URL} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Response } from './external/models/response.model';

@Injectable()
export class ShellWebSocketService {
  private readonly BINARY_ZERO = new Uint8Array([0]).buffer;
  public rawDataReceived$: Subject<{ dataView: DataView, endOfMessage: boolean }> = new Subject();
  public dataReceived$: Subject<DataView> = new Subject();
  public connectionDied$: Subject<void> = new Subject();
  public onLoaded$: Subject<void> = new Subject();

  private isConnected = false;
  private messageQueue: ArrayBuffer[] = [];
  public isChangeCodePageCommandSent = false;
  public isChangeCodePageResponseReceived = false;
  public isReady = true;
  public pendingData: Uint8Array = new Uint8Array();

  private readonly headers: HttpHeaders;
  private readonly options: object;

  constructor(
    private webSocketService: WebSocketService,
    private http: HttpClient,
  ) {
    this.receiveMessageHandler();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.options = {
      headers: this.headers,
      withCredentials: true
    };
  }

  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${API_URL}/cis-cc-coupler/healthz`, this.options);
  }

  public connectWithWebsocket(streamId: string): void {
    const socket = this.webSocketService.connectWithWebsocket(`${WS_URL}/cis-cc-coupler/consumer/${streamId}`);
    socket.onopen = () => {
      this.isConnected = true;
      console.log('[open] Connection established');
      socket.send(this.BINARY_ZERO);
      this.processMessageQueue();
    };
    socket.onclose = () => {
      console.log('[close] Connection closed');
      this.isConnected = false;
      this.connectionDied$.next();
    };

    socket.onerror = (error) => {
      console.error('[error] Connection error:', error);
      this.isConnected = false;
    };
  }

  public sendMessage(message: string): void {
    if (!this.isReady) {
      return;
    }
    const binary = new TextEncoder().encode(message + '\n').buffer;
    this.queueMessage(binary);
    this.queueMessage(this.BINARY_ZERO);
  }

  public closeSocket(): void {
    this.webSocketService.closeSocket();
  }

  private receiveMessageHandler(): void {
    this.webSocketService.messageReceived$.subscribe(data => {
      const temp = new Uint8Array(this.pendingData.buffer);
      this.pendingData = new Uint8Array(temp.length + new Uint8Array(data).length);
      this.pendingData.set(temp, 0);
      this.pendingData.set(new Uint8Array(data), temp.length);

      const lastByte = this.pendingData[this.pendingData.length - 1];
      const endOfMessage = lastByte === 62
      if (endOfMessage) {
        this.isReady = true;

        if (!this.isChangeCodePageCommandSent) {
          this.isChangeCodePageCommandSent = true;
          this.pendingData = new Uint8Array();
          this.sendMessage('chcp 65001');
        } else if (!this.isChangeCodePageResponseReceived) {
          this.isChangeCodePageResponseReceived = true;
          this.pendingData = new Uint8Array();
          this.onLoaded$.next();
        } else {
          this.dataReceived$.next(new DataView(this.pendingData.buffer));
          this.pendingData = new Uint8Array();
        }
      } else {
        this.webSocketService.sendMessage(this.BINARY_ZERO);
      }

      this.rawDataReceived$.next({dataView: new DataView(data), endOfMessage});
    })
  }

  private queueMessage(message: ArrayBuffer): void {
    if (this.isConnected) {
      this.webSocketService.sendMessage(message);
    } else {
      this.messageQueue.push(message);
    }
  }

  private processMessageQueue(): void {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      if (message) {
        this.webSocketService.sendMessage(message);
      }
    }
  }
}
