import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddressModel} from '../../model/employee/address.model';
import {ADDRESS_SAVED_SUCCESSFULLY,} from '../../../../locale/multilingual-strings-constants';
import {NotifierService} from '../../services/notifier.service';
import { PersonalDataService } from '../../services/external/personal-data.service';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressPopupComponent {
  private readonly personalDataId: string;
  public addressForm: FormGroup;

  constructor(
              private ref: DynamicDialogRef,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private config: DynamicDialogConfig,
              private cdr: ChangeDetectorRef,
              private personalDataService: PersonalDataService) {

    const address = config.data.address;
    this.personalDataId = config.data.personalDataId;
    this.addressForm = this.fb.group({
      id: [address?.id],
      personalDataId: [address?.personalDataId || config.data.personalDataId],
      description: [address?.description, [Validators.required,]],
      country: [address?.country, [Validators.required,]],
      state: [address?.state, [Validators.required,]],
      city: [address?.city, [Validators.required,]],
      streetAddress: [address?.streetAddress, [Validators.required,]],
      postalCode: [address?.postalCode, [Validators.required,]],
    });
  }

  public submit() {
    if (this.addressForm.valid) {
      this.addressForm.disable();
      const body = this.addressForm.value as AddressModel;
      const request$ = this.addressForm.value.id ? this.personalDataService.updateAddress(body) :
        this.personalDataService.addAddress(body);

      request$.subscribe({
        next: response => {
          if (response.succeed) {
            this.notifierService.handleSuccessRequest(ADDRESS_SAVED_SUCCESSFULLY);
            if (!this.addressForm.value.id) {
              this.addressForm.controls['id'].setValue(response.value);
              this.closeDialog(true);
            } else this.closeDialog(false, true);
          } else {
            this.notifierService.handleErrors(response.errors);
            this.addressForm.enable();
          }
          this.cdr.detectChanges();
        },
        error: err => {
          this.notifierService.handleRequestError(err);
          this.addressForm.enable();
          this.cdr.detectChanges();
        }
      })
    }
  }

  public closeDialog(added: boolean, edited?: boolean) {
    this.ref.close({added, edited, address: this.addressForm.value});
  }

}
