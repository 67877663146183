import {Pipe, PipeTransform} from '@angular/core';
import { SyncStatusEnum } from './shared/services/external/models/sync-status.enum';

@Pipe({
  name: 'fileStateIcon'
})
export class FileStateIconPipe implements PipeTransform {

  transform(value: SyncStatusEnum): { iconClass: string, color: string, summary: string } {
    switch (value) {
      case SyncStatusEnum.pending:
        return {iconClass: 'pi pi-clock', color: '#f0ad4e', summary: 'pending'};
      case SyncStatusEnum.syncing:
        return {iconClass: 'pi pi-spin pi-spinner', color: '#337ab7', summary: 'syncing'};
      case SyncStatusEnum.synced:
        return {iconClass: 'pi pi-check', color: '#5cb85c', summary: 'synced'};
      case SyncStatusEnum.skipped:
        return {iconClass: 'pi pi-forward', color: '#f0ad4e', summary: 'skipped'};
      case SyncStatusEnum.error:
        return {iconClass: 'pi pi-times', color: '#d9534f', summary: 'error'};
      case SyncStatusEnum.notFound:
        return {iconClass: 'pi pi-exclamation-triangle', color: '#ffa700', summary: 'not found'};
      default:
        return {iconClass: '', color: '', summary: ''};
    }
  }
}
